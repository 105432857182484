import React, { FC } from 'react';
import { EPlatform } from '../../../electrolyse-shared/interfaces';
import { FaApple, FaLinux, FaWindows } from 'react-icons/fa';

interface IProps {
  platform: EPlatform;
  noText?: boolean;
}

export const FileLogo: FC<IProps> = ({ platform, noText }) => {
  return (
    <span className='inline-flex items-center'>
      {platform === EPlatform.Windows && <FaWindows title={EPlatform.Windows} className='text-gray-400 text-xl' />}
      {platform === EPlatform.MacOS && <FaApple title={EPlatform.MacOS} className='text-gray-400 text-xl' />}
      {platform === EPlatform.Linux && <FaLinux title={EPlatform.Linux} className='text-gray-400 text-xl' />}
      {!noText && <span className='ml-2'>{platform}</span>}
    </span>
  );
};
