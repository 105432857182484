import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { ContentWrapper } from '../global/ContentWrapper';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Skeleton } from '../ui/loading/Skeleton';
import { useLoading } from '../../hooks/useLoading';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

interface IProps extends RouteComponentProps {}

export const PasswordScreen: FC<IProps> = () => {
  const { setLoading } = useLoading();
  const { setBreadcrumbs } = useBreadcrumbs();

  useAsyncEffect(async () => {
    setBreadcrumbs([{ title: 'Change password (under construction)', path: APP_PATHS.MAIN }]);
    setLoading(false);
  }, []);

  return (
    <ScreenLayout privatePath title={<Breadcrumbs />}>
      <ContentWrapper>
        <dl>
          <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Old password</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <input className='rounded-md px-4 py-2 w-full border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150' />
            </dd>
          </div>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>New password</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <input className='rounded-md px-4 py-2 w-full border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150' />
            </dd>
          </div>
        </dl>
      </ContentWrapper>
    </ScreenLayout>
  );
};
