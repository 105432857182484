import React, { FC } from 'react';
import { UserLinkLabel } from '../ui/label/UserLinkLabel';
import { useUser } from '../../hooks/useUser';
import { IUser } from '../../../electrolyse-shared/interfaces';

interface IProps {
  outerUser?: IUser;
}

export const CreatedBy: FC<IProps> = ({ outerUser }) => {
  const { user } = useUser();

  if (user && outerUser) {
    return <div>{user._id === outerUser._id ? <div>You</div> : <UserLinkLabel to={''} name={user.nickName} />}</div>;
  }

  return null;
};
