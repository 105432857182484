import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { usePortal } from '../../../hooks/usePortal';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export interface IModal {
  id: number;
  showOverlay: boolean;
  closeByEscapeKey: boolean;
  closeByEnterKey: boolean;
  closeByOutsideClick: boolean;
  onDidClose: () => void;
  onDidOpen: () => void;
  onWillClose: () => void;
  onWillOpen: (modalId: number) => void;
  onEnter?: () => void;
  renderModalComponent: (modalId: number) => React.ReactNode | Element;
  isOpened: boolean;
}

export interface IModalSharedOptions {
  showOverlay?: boolean;
  closeByEscapeKey?: boolean;
  closeByEnterKey?: boolean;
  closeByOutsideClick?: boolean;
  onDidClose?: () => void;
  onDidOpen?: () => void;
  onWillClose?: () => void;
  onWillOpen?: (modalId: number) => void;
  onEnter?: () => void;
}

interface IModalOptions extends IModalSharedOptions {
  renderModalComponent: (modalId: number) => React.ReactNode | Element;
}

interface IProps {
  portalRootSelector: string;
  avoidOnClickRootSelector: string;
}

const ANIMATION_TIME: number = 200;

export interface IModalContext {
  openModal: (options: IModalOptions) => IModal | null;
  closeModal: (modalId: number) => void;
  closeAllModals: () => void;
  isOpened: (modalId: number) => boolean;
}

export const ModalsContext = React.createContext<IModalContext>({
  openModal: () => null,
  closeModal: () => {},
  closeAllModals: () => {},
  isOpened: () => false,
});

export const Modals: React.FC<IProps> = ({ portalRootSelector, avoidOnClickRootSelector, children }) => {
  const modalsRef = useRef(new Map<number, IModal>());
  const topModalIdRef = useRef<number | null>(null);
  const root = usePortal(portalRootSelector);
  const currentModalRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = () => {
    if (!topModalIdRef.current) {
      return;
    }
    const modal = modalsRef.current.get(topModalIdRef.current);
    if (modal?.closeByOutsideClick) {
      closeModal(topModalIdRef.current);
    }
  };

  useOnClickOutside(handleOutsideClick, currentModalRef, avoidOnClickRootSelector);

  /**
   * Utility timestamp to force re-renders due to update the notifications Map ref.
   * Used to strict component re-rendering manually.
   */
  const [update, setUpdate] = useState(Date.now());

  const isShowOverlay = useMemo(() => {
    let isAnyModalOpened = false;
    let showOverlay = false;

    modalsRef.current.forEach(modal => {
      if (modal.showOverlay) {
        showOverlay = true;
      }

      if (modal.isOpened) {
        isAnyModalOpened = true;
      }
    });

    if (!isAnyModalOpened) {
      showOverlay = false;
    }

    return showOverlay;
  }, [update]);

  const isOpened = useCallback((modalId: number) => {
    const modal = modalsRef.current.get(modalId);
    return !!modal?.isOpened;
  }, []);

  const closeAllModals = useCallback(() => {
    modalsRef.current.forEach(modal => {
      modal.onWillClose();
      modal.isOpened = false;
    });

    setUpdate(Date.now());

    requestAnimationFrame(() => {
      setTimeout(() => {
        modalsRef.current = new Map();
        setUpdate(Date.now());
      }, ANIMATION_TIME);
    });
  }, []);

  const closeModal = useCallback((id: number) => {
    const modal = modalsRef.current.get(id);

    if (modal) {
      requestAnimationFrame(() => {
        modal.onWillClose();
        modal.isOpened = false;
        setUpdate(Date.now());
        setTimeout(() => {
          modalsRef.current.delete(id);
          setUpdate(Date.now());
        }, ANIMATION_TIME);
      });
    }
  }, []);

  const openModal = useCallback((options: IModalOptions) => {
    const id = Date.now();
    const {
      renderModalComponent,
      closeByEscapeKey = true,
      closeByEnterKey = false,
      closeByOutsideClick = true,
      showOverlay = true,
      onDidClose = () => {},
      onDidOpen = () => {},
      onWillClose = () => {},
      onWillOpen = () => {},
      onEnter = () => {},
    } = options;

    modalsRef.current.set(id, {
      id,
      renderModalComponent,
      showOverlay,
      closeByEscapeKey,
      closeByEnterKey,
      closeByOutsideClick,
      onDidClose,
      onDidOpen,
      onWillClose,
      onWillOpen,
      onEnter,
      isOpened: false,
    });

    setUpdate(Date.now());

    const modal = modalsRef.current.get(id);

    if (modal) {
      topModalIdRef.current = id;
      requestAnimationFrame(() => {
        modal.isOpened = true;
        modal.onWillOpen(id);
        setUpdate(Date.now());
      });
      return modal;
    }

    return null;
  }, []);

  const handleOnExitedAnimation = useCallback((modal: IModal) => {
    modal.onDidClose();
    modalsRef.current.delete(modal.id);

    if (modalsRef.current.size > 0) {
      topModalIdRef.current = Array.from(modalsRef.current.values()).sort((a, b) => b.id - a.id)[0].id;
    } else {
      topModalIdRef.current = null;
    }

    setUpdate(Date.now());
  }, []);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (topModalIdRef.current !== null) {
      const modal = modalsRef.current.get(topModalIdRef.current);

      if (modal) {
        switch (e.keyCode) {
          // ESC
          case 27: {
            if (modal?.closeByEscapeKey) {
              closeModal(topModalIdRef.current);
            }

            break;
          }

          // Return
          case 13: {
            if (modal?.onEnter) {
              modal?.onEnter();
            }

            if (modal?.closeByEnterKey) {
              closeModal(topModalIdRef.current);
            }

            break;
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);
    return () => {
      document.removeEventListener('keyup', handleKeyUp, false);
    };
  }, []);

  if (!root) {
    return null;
  }
  const modals = Array.from(modalsRef.current.values());
  return (
    <ModalsContext.Provider
      value={{
        closeModal,
        openModal,
        closeAllModals,
        isOpened,
      }}>
      {children}
      {modals.length > 0 &&
        ReactDOM.createPortal(
          <div className='fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center'>
            <CSSTransition
              timeout={ANIMATION_TIME}
              in={isShowOverlay}
              unmountOnExit
              classNames={{
                enter: 'opacity-0',
                enterActive: `ease-out duration-${ANIMATION_TIME} opacity-100`,
                enterDone: 'opacity-100',
                exitActive: `ease-in duration-${ANIMATION_TIME} opacity-0`,
              }}>
              <div className='fixed inset-0 transition-opacity' onClick={handleOutsideClick}>
                <div className='w-screen h-screen inset-0 bg-gray-500 opacity-75' />
              </div>
            </CSSTransition>
            {modals.map(modal => {
              return (
                <CSSTransition
                  key={modal.id}
                  timeout={ANIMATION_TIME}
                  in={modal.isOpened}
                  unmountOnExit
                  onExited={() => handleOnExitedAnimation(modal)}
                  onEntered={() => {
                    modal.onDidOpen();
                  }}
                  classNames={{
                    enter: 'opacity-0 scale-95',
                    enterActive: `opacity-100 scale-100 ease-out duration-${ANIMATION_TIME}`,
                    enterDone: `opacity-100 scale-100`,
                    exitActive: `opacity-0 scale-95 ease-in transition-all duration-${ANIMATION_TIME}`,
                  }}>
                  {modal.renderModalComponent(modal.id)}
                </CSSTransition>
              );
            })}
          </div>,
          root,
        )}
    </ModalsContext.Provider>
  );
};
