import React, { FC, ReactNode } from 'react';
import { Loading } from './Loading';
import { useAuth } from '../../hooks/useAuth';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { useLoading } from '../../hooks/useLoading';
import { Header } from './Header';

interface IProps {
  title: ReactNode;
  privatePath?: boolean;
  button?: ReactNode;
}

export const ScreenLayout: FC<IProps> = ({ title, button, privatePath = true, children }) => {
  const { checkAuth } = useAuth();
  const { globalLoading, setGlobalLoading } = useLoading();

  useAsyncEffect(async () => {
    await checkAuth(privatePath);
    setGlobalLoading(false);
  }, []);

  if (globalLoading) {
    return null;
  }

  return (
    <div className='bg-gray-100 min-h-screen'>
      <Header />
      <header className='bg-white shadow'>
        <div className='max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex justify-between items-center h-20'>
          <h1 className='text-3xl font-bold leading-tight text-gray-900'>{title}</h1>
          {button}
        </div>
      </header>
      <main className='h-full'>
        <div className='max-w-7xl mx-auto py-12 sm:px-6 lg:px-8'>{children}</div>
      </main>
      <Loading />
    </div>
  );
};
