import React, { FC, useState, Fragment } from 'react';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Button, EButtonStyle } from '../ui/button/Button';
import { ContentWrapper } from '../global/ContentWrapper';
import { ReleasesList } from '../global/ReleasesList';
import { NothingHere } from '../global/NothingHere';
import { ScreenLayout } from '../global/ScreenLayout';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { IProject, IRelease } from '../../../electrolyse-shared/interfaces';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import axios from 'axios';
import { API_PATHS } from '../../../electrolyse-shared/api-paths';
import { NotFound } from '../global/NotFound';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { useGetProject } from '../../hooks/useGetProject';
import { useLoading } from '../../hooks/useLoading';
import { ProjectNav } from '../global/ProjectNav';
import { Skeleton } from '../ui/loading/Skeleton';

interface IProps extends RouteComponentProps {
  id?: string;
}

export const ReleasesScreen: FC<IProps> = ({ id }) => {
  const navigate = useNavigate();
  const { setLoading, loading } = useLoading();
  const [releases, setReleases] = useState<IRelease[]>([]);
  const [project, setProject] = useState<IProject | null>(null);
  const { setBreadcrumbs } = useBreadcrumbs();
  const { getProjectById } = useGetProject();
  const [notFound, setNotFound] = useState(false);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const releasesRequest = await axios.get<{ items: IRelease[] }>(
        API_PATHS.GET_PROJECT_RELEASES.replace(':id', id ?? ''),
      );
      const project = await getProjectById(id ?? '');
      setReleases(releasesRequest.data.items);
      setProject(project);
      setBreadcrumbs([
        { title: 'Projects', path: APP_PATHS.PROJECTS },
        { title: project.name, path: APP_PATHS.PROJECT.replace(':id', id ?? '') },
        { title: 'Releases', path: APP_PATHS.PROJECT_RELEASES.replace(':id', id ?? '') },
      ]);
      if (!project) {
        setNotFound(true);
      }
      return setLoading(false);
    } catch (e) {}
    setNotFound(true);
    setLoading(false);
  }, []);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <ScreenLayout
      title={<Breadcrumbs />}
      button={
        <div>
          <Button
            style={EButtonStyle.Accent}
            attrs={{
              onClick: () => navigate(APP_PATHS.NEW_RELEASE.replace(':id', id ?? '')),
            }}>
            New release
          </Button>
        </div>
      }>
      <ProjectNav projectId={id ?? ''} />

      <ContentWrapper>
        {loading && <Skeleton />}
        {!loading && project && (
          <Fragment>
            {releases.length > 0 ? (
              <ReleasesList project={project} releases={releases} />
            ) : (
              <NothingHere>Nothing released yet</NothingHere>
            )}
          </Fragment>
        )}
      </ContentWrapper>
    </ScreenLayout>
  );
};
