import * as serviceWorker from './app/serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { App } from './app/components/App';
import './app/tailwind.generated.css';
import { EStorageName } from './app/common/storage';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  const token = localStorage.getItem(EStorageName.Token);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
