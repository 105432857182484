import React, { FC } from 'react';
import { MainScreen } from '../screens/MainScreen';
import { Router } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { ReleaseScreen } from '../screens/ReleaseScreen';
import { NewReleaseScreen } from '../screens/NewReleaseScreen';
import { ProjectsScreen } from '../screens/ProjectsScreen';
import { NewProjectScreen } from '../screens/NewProjectScreen';
import { ReleasesScreen } from '../screens/ReleasesScreen';
import { ProjectScreen } from '../screens/ProjectScreen';
import { NotFoundScreen } from '../screens/NotFoundScreen';
import { EventsScreen } from '../screens/EventsScreen';
import { StatisticsScreen } from '../screens/StatisticsScreen';
import { LoginScreen } from '../screens/LoginScreen';
import { RegisterScreen } from '../screens/RegisterScreen';
import { RememberPasswordScreen } from '../screens/RememberPasswordScreen';
import { BillingScreen } from '../screens/BillingScreen';
import { PasswordScreen } from '../screens/PasswordScreen';
import { ProfileScreen } from '../screens/ProfileScreen';
import { ProjectSettingsScreen } from '../screens/ProjectSettingsScreen';
import { ProjectInviteScreen } from '../screens/ProjectInviteScreen';
import { InviteScreen } from '../screens/InviteScreen';
import { ProjectIntegrationScreen } from '../screens/ProjectIntegrationScreen';
import { ProjectStatsScreen } from '../screens/ProjectStatsScreen';

interface IProps {}

export const Routes: FC<IProps> = () => {
  return (
    <Router>
      <NotFoundScreen default />
      <LoginScreen path={APP_PATHS.AUTH_LOGIN} />
      <RegisterScreen path={APP_PATHS.AUTH_REGISTER} />
      <RememberPasswordScreen path={APP_PATHS.AUTH_REMEMBER_PASSWORD} />
      <EventsScreen path={APP_PATHS.EVENTS} />
      <StatisticsScreen path={APP_PATHS.STATISTICS} />
      <MainScreen path={APP_PATHS.MAIN} />
      <ProjectsScreen path={APP_PATHS.PROJECTS} />
      <NewProjectScreen path={APP_PATHS.NEW_PROJECT} />
      <ProjectScreen path={APP_PATHS.PROJECT} />
      <ReleasesScreen path={APP_PATHS.PROJECT_RELEASES} />
      <ProjectSettingsScreen path={APP_PATHS.PROJECT_SETTINGS} />
      <ProjectStatsScreen path={APP_PATHS.PROJECT_STATS} />
      <ProjectInviteScreen path={APP_PATHS.PROJECT_INVITE} />
      <ReleaseScreen path={APP_PATHS.RELEASE} />
      <NewReleaseScreen path={APP_PATHS.NEW_RELEASE} />
      <BillingScreen path={APP_PATHS.USER_BILLING} />
      <PasswordScreen path={APP_PATHS.USER_PASSWORD} />
      <ProfileScreen path={APP_PATHS.USER_PROFILE} />
      <InviteScreen path={APP_PATHS.AUTH_INVITE} />
      <ProjectIntegrationScreen path={APP_PATHS.PROJECT_INTEGRATION} />
    </Router>
  );
};
