import React, { FC } from 'react';
import { ActiveLink } from '../links/ActiveLink';

interface ITabsNavItem {
  title: string;
  path: string;
}

interface IProps {
  items: ITabsNavItem[];
}

export const TabsNav: FC<IProps> = ({ items }) => {
  return (
    <nav className='flex'>
      {items.map(item => (
        <ActiveLink
          to={item.path}
          key={item.path}
          activeClassName='mr-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-800 bg-gray-200 focus:outline-none focus:bg-gray-300 transition-color duration-150'
          className='mr-4 px-3 py-2 font-medium text-sm leading-5 rounded-md text-gray-600 hover:text-gray-800 focus:outline-none focus:text-gray-800 focus:bg-gray-200 transition-color duration-150'>
          {item.title}
        </ActiveLink>
      ))}
    </nav>
  );
};
