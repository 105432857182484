import React, { FC } from 'react';
import classnames from 'classnames';

interface IProps {
  value: number;
}

export const Changes: FC<IProps> = ({ value }) => {
  if (value === 0) {
    return null;
  }

  return (
    <div
      className={classnames(
        {
          'bg-red-100 text-red-800': value < 0,
          'bg-green-100 text-green-800': value > 0,
        },
        'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium leading-5 md:mt-2 lg:mt-0',
      )}>
      {value > 0 ? (
        <svg
          className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
          fill='currentColor'
          viewBox='0 0 20 20'>
          <path
            fill-rule='evenodd'
            d='M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z'
            clip-rule='evenodd'
          />
        </svg>
      ) : (
        <svg
          className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
          fill='currentColor'
          viewBox='0 0 20 20'>
          <path
            fill-rule='evenodd'
            d='M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z'
            clip-rule='evenodd'
          />
        </svg>
      )}
      <span className='sr-only'>Increased by</span>
      {(value < 0 ? -value : value).toLocaleString('en', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })}
      %
    </div>
  );
};
