import React, { FC, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { Link } from '@reach/router';
import { Avatar, EAvatarSize } from '../ui/avatar/Avatar';
import { CSSTransition } from 'react-transition-group';
import { RiUser6Line, RiLockPasswordLine, RiLogoutCircleRLine, RiPriceTag2Line } from 'react-icons/ri';
import { useUser } from '../../hooks/useUser';
import { useAuth } from '../../hooks/useAuth';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import classNames from 'classnames';

interface IProps {}

const ANIMATION_TIME = 200;

export const ProfileMenu: FC<IProps> = () => {
  const [profileMenu, setProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);
  const { user } = useUser();
  const { logOut } = useAuth();
  const handleProfileMenu = (e: any) => {
    e.preventDefault();
    setProfileMenu(!profileMenu);
  };
  useOnClickOutside(() => {
    setProfileMenu(false);
  }, profileMenuRef);

  return (
    <div className='relative' ref={profileMenuRef}>
      <div className='ml-6 flex items-center'>
        <div
          className={classNames(
            'border-2 border-transparent rounded-full hover:border-gray-400 transition-colors duration-150',
            {
              'border-gray-300': profileMenu,
            },
          )}>
          <Link to={''} onClick={handleProfileMenu}>
            <Avatar words={user?.nickName || user?.email || ''} size={EAvatarSize.Md} />
          </Link>
        </div>
      </div>
      <CSSTransition
        timeout={ANIMATION_TIME}
        in={profileMenu}
        unmountOnExit
        classNames={{
          enter: 'opacity-0 scale-95',
          enterActive: `opacity-100 scale-100 ease-out duration-${ANIMATION_TIME}`,
          enterDone: `opacity-100 scale-100`,
          exitActive: `opacity-0 scale-95 ease-in transition-all duration-${ANIMATION_TIME}`,
        }}>
        <div className='absolute z-50 mt-4 right-0 bg-white rounded shadow-xl transform w-64'>
          <div className='rounded shadow-xs'>
            <header className='px-5 py-3 border-b border-gray-200'>
              <div className='text-gray-500 text-xs'>Your profile</div>
              <div className='text-sm'>{user?.email}</div>
            </header>
            <div className='flex flex-wrap justify-between p-5 pt-0 text-center leading-tight'>
              <Link
                to={APP_PATHS.USER_PROFILE}
                className='bg-gray-50 rounded w-24 mt-5 flex-shrink-0 pt-5 pb-2 h-24 flex flex-col items-center hover:bg-blue-50 transition-color duration-150'>
                <RiUser6Line className='h-8 w-8 mb-2 text-gray-500' />
                <span className='text-xs text-gray-600'>Profile</span>
              </Link>
              <Link
                to={APP_PATHS.USER_BILLING}
                className='bg-gray-50 rounded w-24 mt-5 flex-shrink-0 pt-5 pb-2 h-24 flex flex-col items-center hover:bg-blue-50 transition-color duration-150'>
                <RiPriceTag2Line className='h-8 w-8 mb-2 text-gray-500' />
                <span className='text-xs text-gray-600'>Billing</span>
              </Link>
              <Link
                to={APP_PATHS.USER_PASSWORD}
                className='bg-gray-50 rounded w-24 mt-5 flex-shrink-0 pt-5 pb-2 h-24 flex flex-col items-center hover:bg-blue-50 transition-color duration-150'>
                <RiLockPasswordLine className='h-8 w-8 mb-2 text-gray-500' />
                <span className='text-xs text-gray-600'>Password</span>
              </Link>
              <a
                href='#'
                onClick={logOut}
                className='bg-gray-50 rounded w-24 mt-5 flex-shrink-0 pt-5 pb-2 h-24 flex flex-col items-center hover:bg-blue-50 transition-color duration-150'>
                <RiLogoutCircleRLine className='h-8 w-8 mb-2 text-gray-500' />
                <span className='text-xs text-gray-600'>Log out</span>
              </a>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
