import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Project } from '../global/Project';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { useLoading } from '../../hooks/useLoading';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

interface IProps extends RouteComponentProps {}

export const NewProjectScreen: FC<IProps> = () => {
  const { setLoading } = useLoading();
  const { setBreadcrumbs } = useBreadcrumbs();

  useAsyncEffect(async () => {
    setBreadcrumbs([
      { title: 'Projects', path: APP_PATHS.PROJECTS },
      { title: 'New project', path: APP_PATHS.NEW_PROJECT },
    ]);
    setLoading(false);
  }, []);

  return (
    <ScreenLayout title={<Breadcrumbs />}>
      <Project />
    </ScreenLayout>
  );
};
