import React, { FC, useReducer, useRef, useState } from 'react';
import classnames from 'classnames';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';

export interface IDropdownItem<T = any> {
  id: string;
  title: string;
  value: T;
}

interface IProps<T = any> {
  items: IDropdownItem<T>[];
  onSelect: (item: IDropdownItem<T>) => void;
  placeholder?: string;
  current?: IDropdownItem<T>;
}

export function DropdownMenu<T>({ items, current, placeholder, onSelect }: IProps<T>) {
  const [opened, setOpened] = useState(false);
  const currentRef = useRef(null);

  useOnClickOutside(() => {
    setOpened(false);
  }, currentRef);

  const toggle = () => {
    setOpened(!opened);
  };

  return (
    <div className='relative inline-block text-left' ref={currentRef}>
      <div>
        <span className='rounded-md shadow-sm'>
          <button
            onClick={toggle}
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150'>
            {current ? current.title : placeholder ? placeholder : '–'}
            <svg className='-mr-1 ml-2 h-5 w-5' fill='currentColor' viewBox='0 0 20 20'>
              <path
                fillRule='evenodd'
                d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </button>
        </span>
      </div>

      {opened && (
        <div className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-10'>
          <div className='rounded-md bg-white shadow-xs'>
            <div className='py-1'>
              {items.map(item => (
                <span
                  onClick={() => {
                    onSelect(item);
                    setOpened(false);
                  }}
                  key={item.id}
                  className={classnames(
                    'cursor-pointer block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900',
                    { 'bg-blue-100 hover:bg-blue-100 cursor-default': current && current.value === item.value },
                  )}>
                  {item.title}
                </span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
