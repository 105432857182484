import React, { FC, Fragment, useContext, useState } from 'react';
import { EChannel, EStatus, IProject, IRelease } from '../../../electrolyse-shared/interfaces';
import { Button, EButtonStyle } from '../ui/button/Button';
import { DropdownMenu } from '../ui/dropdown/DropdownMenu';
import { DEFAULT_RELEASE } from '../../common/defaults';
import axios from 'axios';
import { API_PATHS } from '../../../electrolyse-shared/api-paths';
import { NotificationsContext } from '../ui/notification/Notifications';
import { useNavigate } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { ErrorFields } from '../ui/alert/ErrorFields';
import classNames from 'classnames';
import { useParseFieldErrors } from '../../hooks/useParseFieldErrors';
import { ReleaseFiles } from './ReleaseFiles';
import { useModal } from '../../hooks/useModal';
import { DangerModal } from '../ui/modal/DangerModal';
import { ConfirmModal } from '../ui/modal/ConfirmModal';
import { ReleaseCompare } from './ReleaseCompare';
import { useGetRelease } from '../../hooks/useGetRelease';
import { useLoading } from '../../hooks/useLoading';
import { format } from 'date-fns';
import { EPartialStyle } from '../../enums/EPartialStyle';
import { CreatedBy } from './CreatedBy';

interface IProps {
  project: IProject;
  release?: IRelease;
}

const STATUS_LIST = [
  { id: EStatus.Active, title: EStatus.Active, value: EStatus.Active },
  { id: EStatus.Inactive, title: EStatus.Inactive, value: EStatus.Inactive },
  { id: EStatus.Archived, title: EStatus.Archived, value: EStatus.Archived },
  { id: EStatus.Invalid, title: EStatus.Invalid, value: EStatus.Invalid },
];

const CHANNEL_LIST = [
  { id: EChannel.Latest, title: EChannel.Latest, value: EChannel.Latest },
  { id: EChannel.Beta, title: EChannel.Beta, value: EChannel.Beta },
  { id: EChannel.Alpha, title: EChannel.Alpha, value: EChannel.Alpha },
];

export const Release: FC<IProps> = ({ release: initialRelease = DEFAULT_RELEASE, project }) => {
  const { setLocalLoading, localLoading } = useLoading();
  const { getReleaseById } = useGetRelease();
  const getErrors = useParseFieldErrors();
  const navigate = useNavigate();
  const notificationsContext = useContext(NotificationsContext);
  const [release, setRelease] = useState(initialRelease);
  const isNew = release._id === DEFAULT_RELEASE._id;
  const commitChangesModal = useModal(
    ({ handleClose }) => (
      <ConfirmModal
        {...{
          handleClose,
          title: 'Commit changes?',
          agreeText: 'Commit',
          cancelText: 'Cancel',
          handleAgree: () => commitChanges(),
        }}>
        <ReleaseCompare releaseOne={initialRelease} releaseTwo={release} />
      </ConfirmModal>
    ),
    { onEnter: () => commitChanges() },
  );
  const deleteModal = useModal(
    ({ handleClose }) => (
      <DangerModal
        {...{
          handleClose,
          title: 'Are you sure want to delete this release?',
          agreeText: 'Commit',
          cancelText: 'Cancel',
          handleAgree: () => deleteRelease(),
        }}>
        Are you sure want to delete this release?
      </DangerModal>
    ),
    {
      onEnter: () => deleteRelease(),
    },
  );

  const commitChanges = async () => {
    commitChangesModal.close();
    setLocalLoading(true);
    try {
      const result = await axios.patch<{ item: IRelease }>(
        API_PATHS.PATCH_RELEASE.replace(':id', release?._id ?? ''),
        release,
      );
      setRelease(result.data.item);
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Release updated',
        title: 'Success',
      });
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: <ErrorFields fields={getErrors(e)} />,
        title: e.response?.data?.error,
      });
    }
    setLocalLoading(false);
  };

  const handleUpdate = async () => {
    setLocalLoading(true);
    const result = await getReleaseById(release?._id ?? '');
    if (result) {
      setRelease(result);
    }
    setLocalLoading(false);
  };

  const createRelease = async () => {
    setLocalLoading(true);
    try {
      const result = await axios.put<{ item: IRelease }>(API_PATHS.PUT_NEW_RELEASE, {
        ...release,
        projectId: project._id,
      });
      if (result.data.item._id) {
        await navigate(
          APP_PATHS.RELEASE.replace(':projectId', project?._id ?? '').replace(':id', result.data.item._id),
        );
      }
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Release created',
        title: 'Success',
      });
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: <ErrorFields fields={getErrors(e)} />,
        title: e.response?.data?.error,
      });
    }
    setLocalLoading(false);
  };

  const deleteRelease = async () => {
    setLocalLoading(true);
    try {
      await axios.delete(API_PATHS.DELETE_RELEASE.replace(':id', release?._id ?? ''));
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Release deleted',
        title: 'Success',
      });
      await navigate(APP_PATHS.PROJECT_RELEASES.replace(':id', project?._id ?? ''));
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: e.response?.data?.error,
        title: 'Error',
      });
    }
    setLocalLoading(false);
    deleteModal.close();
  };

  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-lg my-2'>
      <div className='px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-between'>
        <div />
        {isNew ? (
          <div className='flex items-center'>
            <div className='max-w-7xl ml-6'>
              <Button
                disabled={localLoading}
                style={EButtonStyle.Accent}
                attrs={{
                  onClick: () => createRelease(),
                }}>
                Create release
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex items-center'>
            {release.justCreated && (
              <div className='max-w-7xl ml-6'>
                <Button
                  disabled={localLoading}
                  style={EButtonStyle.Default}
                  attrs={{
                    onClick: () => deleteModal.open(),
                  }}>
                  Delete
                </Button>
              </div>
            )}
            <div className='max-w-7xl ml-6'>
              <Button
                disabled={localLoading}
                style={EButtonStyle.Accent}
                attrs={{
                  onClick: () => commitChangesModal.open(),
                }}>
                Commit changes
              </Button>
            </div>
          </div>
        )}
      </div>
      <div>
        <dl>
          {!isNew && (
            <Fragment>
              <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Created by</dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  <CreatedBy outerUser={release.user} />
                </dd>
              </div>

              <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
                <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Created at</dt>
                <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
                  {format(new Date(release.date), 'MMM d, yyyy H:mm:ss')}
                </dd>
              </div>
            </Fragment>
          )}
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Version name</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <input
                placeholder='Must be semver and greater than previous releases'
                className={classNames(
                  'rounded-md px-4 py-2 w-full border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150',
                  {
                    'text-gray-400': !isNew,
                  },
                )}
                value={release.version || ''}
                disabled={!isNew}
                onChange={event => {
                  setRelease({
                    ...release,
                    version: event.target.value,
                  });
                }}
              />
            </dd>
          </div>

          <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Distribution</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <div className='mt-1 relative rounded-md shadow-sm'>
                <input
                  className='form-input block w-full pl-7 pr-12 sm:text-sm sm:leading-5'
                  placeholder='0'
                  value={release.distribution}
                  onChange={event => {
                    let distribution = parseInt(event.target.value) || 0;
                    if (distribution > 100) {
                      distribution = 100;
                    } else if (distribution < 0) {
                      distribution = 0;
                    }
                    setRelease({
                      ...release,
                      distribution,
                    });
                  }}
                />
                <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
                  <span className='text-gray-500 sm:text-sm sm:leading-5'>%</span>
                </div>
              </div>
            </dd>
          </div>

          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Status</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <DropdownMenu
                items={STATUS_LIST}
                current={STATUS_LIST.find(s => s.value === release.status)}
                onSelect={item => {
                  setRelease({
                    ...release,
                    status: item.value,
                  });
                }}
                placeholder='Current status'
              />
            </dd>
          </div>

          <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Channel</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <DropdownMenu
                items={CHANNEL_LIST}
                current={CHANNEL_LIST.find(s => s.value === release.channel)}
                onSelect={item => {
                  setRelease({
                    ...release,
                    channel: item.value,
                  });
                }}
                placeholder='Current channel'
              />
            </dd>
          </div>

          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Release notes</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <textarea
                value={release.releaseNotes || ''}
                onChange={event => {
                  setRelease({
                    ...release,
                    releaseNotes: event.target.value,
                  });
                }}
                className='rounded-md px-4 py-2 w-full h-32 border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150'
              />
            </dd>
          </div>

          {!isNew && <ReleaseFiles onUpdate={handleUpdate} release={release} />}
        </dl>
      </div>
    </div>
  );
};
