import React, { FC, Fragment } from 'react';
import { IProject } from '../../../electrolyse-shared/interfaces';
import { H2 } from '../ui/typography/H2';
import { RiLinksLine } from 'react-icons/ri';

interface IProps {
  project: IProject;
}

export const ProjectIntegration: FC<IProps> = ({ project }) => {
  return (
    <Fragment>
      <div className='px-4 py-5 border-b border-gray-200 sm:px-6'>
        <h3 className='text-lg leading-6 font-medium text-gray-900'>Applicant Information</h3>
        <p className='mt-1 max-w-2xl text-sm leading-5 text-gray-500'>Personal details and application.</p>
      </div>
      <div className='px-4 py-5 sm:p-0'>
        <dl>
          <div className='mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Project ID</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>{project._id}</dd>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Updater URL</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 flex items-center'>
              <RiLinksLine className='mr-2' />
              {`${process.env.REACT_APP_API_URL}/update/${project._id}/`}
            </dd>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Download Windows installer URL</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 flex items-center'>
              <RiLinksLine className='mr-2' />
              {`${process.env.REACT_APP_API_URL}/download/${project._id}/windows`}
            </dd>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Download macOS installer URL</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 flex items-center'>
              <RiLinksLine className='mr-2' />
              {`${process.env.REACT_APP_API_URL}/download/${project._id}/mac`}
            </dd>
          </div>
          <div className='sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5 sm:border-t sm:border-gray-200'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Download Linux installer URL</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2 flex items-center'>
              <RiLinksLine className='mr-2' />
              {`${process.env.REACT_APP_API_URL}/download/${project._id}/linux`}
            </dd>
          </div>
        </dl>
      </div>
    </Fragment>
  );
};
