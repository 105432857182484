import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useLoading } from '../../hooks/useLoading';
import { NotFound } from '../global/NotFound';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

interface IProps extends RouteComponentProps {}

export const NotFoundScreen: FC<IProps> = () => {
  const { setLoading } = useLoading();

  useAsyncEffect(async () => {
    setLoading(false);
  }, []);

  return <NotFound />;
};
