import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayoutMinimal } from '../global/ScreenLayoutMinimal';
import { Auth } from '../global/Auth';
import { Anchor } from '../ui/misc/Anchor';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { FormRow } from '../ui/form/FormRow';
import { FormLabel } from '../ui/label/FormLabel';
import { Input } from '../ui/form/Input';
import { Button, EButtonStyle } from '../ui/button/Button';
import { useAuth } from '../../hooks/useAuth';
import { useForm } from 'react-hook-form';
import { useLoading } from '../../hooks/useLoading';
import { NotificationsContext } from '../ui/notification/Notifications';
import { EPartialStyle } from '../../enums/EPartialStyle';
import { IErrorField } from '../../../electrolyse-shared/interfaces';

interface IModel {
  email: string;
  password: string;
}

interface IProps extends RouteComponentProps {}

export const RegisterScreen: FC<IProps> = () => {
  const notificationsContext = useContext(NotificationsContext);
  const { setLoading, setLocalLoading } = useLoading();
  const { signUp } = useAuth();
  const { register, handleSubmit } = useForm<IModel>();
  const [errorFields, setErrorFields] = useState<IErrorField[]>([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  const onSubmit = async (data: IModel) => {
    setErrorFields([]);
    setLocalLoading(true);
    const result = await signUp(data.email, data.password);
    setErrorFields(result?.fields ?? []);
    if (result.error) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: result.error,
        title: 'Error',
      });
    }
    setLocalLoading(false);
  };

  return (
    <ScreenLayoutMinimal privatePath={false}>
      <Auth
        subtitle={
          <Fragment>
            Sign in to&nbsp;<Anchor to={APP_PATHS.AUTH_LOGIN}>your account</Anchor>
          </Fragment>
        }>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FormLabel attrs={{ htmlFor: 'email' }}>Email</FormLabel>
            <Input
              ref={register}
              errorFields={errorFields}
              attrs={{
                id: 'email',
                autoComplete: 'email',
                name: 'email',
                autoFocus: true,
              }}
            />
          </FormRow>
          <FormRow>
            <FormLabel attrs={{ htmlFor: 'password' }}>Password</FormLabel>
            <Input
              ref={register}
              errorFields={errorFields}
              attrs={{
                id: 'password',
                type: 'password',
                name: 'password',
                autoComplete: 'new-password',
              }}
            />
          </FormRow>
          <FormRow type='button'>
            <Button
              style={EButtonStyle.Accent}
              size='large'
              attrs={{
                type: 'submit',
                onClick: () => {},
              }}>
              Sign up
            </Button>
          </FormRow>
          <FormRow type='text'>
            <span className='text-base'>
              By signing up, I agree to <Anchor to='#'>Terms of Service</Anchor>
            </span>
          </FormRow>
        </form>
      </Auth>
    </ScreenLayoutMinimal>
  );
};
