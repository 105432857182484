import React, { FC, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayoutMinimal } from '../global/ScreenLayoutMinimal';
import { Auth } from '../global/Auth';
import { Anchor } from '../ui/misc/Anchor';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { FormRow } from '../ui/form/FormRow';
import { FormLabel } from '../ui/label/FormLabel';
import { Input } from '../ui/form/Input';
import { Button, EButtonStyle } from '../ui/button/Button';
import { useAuth } from '../../hooks/useAuth';
import { useLoading } from '../../hooks/useLoading';
import { useForm } from 'react-hook-form';
import { NotificationsContext } from '../ui/notification/Notifications';
import { EPartialStyle } from '../../enums/EPartialStyle';
import { IErrorField } from '../../../electrolyse-shared/interfaces';

interface IModel {
  email: string;
  password: string;
}

interface IProps extends RouteComponentProps {}

export const LoginScreen: FC<IProps> = () => {
  const { setLoading, setLocalLoading } = useLoading();
  const { signIn } = useAuth();
  const { register, handleSubmit } = useForm<IModel>();
  const [errorFields, setErrorFields] = useState<IErrorField[]>([]);
  const notificationsContext = useContext(NotificationsContext);

  useEffect(() => {
    setLoading(false);
  }, []);

  const onSubmit = async (data: IModel) => {
    setErrorFields([]);
    setLocalLoading(true);
    const result = await signIn(data.email, data.password);
    setErrorFields(result?.fields);
    if (result.error) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: result.error,
        title: 'Error',
      });
    }
    setLocalLoading(false);
  };

  return (
    <ScreenLayoutMinimal privatePath={false}>
      <Auth
        subtitle={
          <div>
            Sign up to <Anchor to={APP_PATHS.AUTH_REGISTER}>create new account</Anchor>
          </div>
        }>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FormLabel attrs={{ htmlFor: 'email' }}>Email</FormLabel>
            <Input
              ref={register}
              errorFields={errorFields}
              attrs={{
                id: 'email',
                name: 'email',
                autoComplete: 'email',
                autoFocus: true,
              }}
            />
          </FormRow>
          <FormRow>
            <FormLabel attrs={{ htmlFor: 'password' }}>Password</FormLabel>
            <Input
              ref={register}
              errorFields={errorFields}
              attrs={{
                id: 'password',
                name: 'password',
                type: 'password',
                autoComplete: 'current-password',
              }}
            />
          </FormRow>
          <FormRow type='button'>
            <Button
              style={EButtonStyle.Accent}
              size='large'
              attrs={{
                type: 'submit',
              }}>
              Sign in
            </Button>
          </FormRow>
          <FormRow type='text'>
            <span className='text-base'>
              <Anchor to={APP_PATHS.AUTH_REMEMBER_PASSWORD}>Remember password</Anchor>
            </span>
          </FormRow>
        </form>
      </Auth>
    </ScreenLayoutMinimal>
  );
};
