import { EStatus } from '../../electrolyse-shared/interfaces';
import { EColor } from '../common/colors';

export const useStatusToColor = () => {
  return (status?: EStatus): EColor => {
    switch (status) {
      case EStatus.Active: {
        return EColor.Green;
      }
      case EStatus.Invalid: {
        return EColor.Red;
      }
      default: {
        return EColor.Gray;
      }
    }
  };
};
