export const APP_PATHS = {
  AUTH_LOGIN: '/auth/login',
  AUTH_REGISTER: '/auth/register',
  AUTH_REMEMBER_PASSWORD: '/auth/remember-password',
  AUTH_INVITE: '/auth/invite/:code',

  USER_BILLING: '/user/billing',
  USER_PASSWORD: '/user/password',
  USER_PROFILE: '/user/profile',

  MAIN: '/',
  STATISTICS: '/statistics',
  EVENTS: '/events',
  NEW_PROJECT: '/projects/create-project',
  PROJECTS: '/projects',
  PROJECT: '/projects/:id',
  PROJECT_SETTINGS: '/projects/:id/settings',
  PROJECT_STATS: '/projects/:id/stats',
  PROJECT_RELEASES: '/projects/:id/releases',
  PROJECT_INVITE: '/projects/:id/invite',
  PROJECT_INTEGRATION: '/projects/:id/integration',

  NEW_RELEASE: '/projects/:id/create-release',
  RELEASE: '/projects/:projectId/releases/:id',
};
