import React, { FC } from 'react';
import './Loading.css';
import { CSSTransition } from 'react-transition-group';
import { useLoading } from '../../hooks/useLoading';
import { RiLoaderLine } from 'react-icons/ri';

interface IProps {}

const ANIMATION_TIME = 200;

export const Loading: FC<IProps> = () => {
  const { loading, localLoading } = useLoading();
  return (
    <CSSTransition
      timeout={ANIMATION_TIME}
      in={loading || localLoading}
      unmountOnExit
      classNames={{
        enter: 'opacity-0 scale-0',
        enterActive: `opacity-100 scale-100 ease-out duration-${ANIMATION_TIME}`,
        enterDone: `opacity-100 scale-100`,
        exitActive: `opacity-0 scale-0 ease-in duration-${ANIMATION_TIME}`,
      }}>
      <div
        className='fixed z-50 flex top-0 left-0 justify-center items-center text-gray-800 w-full h-screen pointer-events-none'>
        <RiLoaderLine className='loading' />
      </div>
    </CSSTransition>
  );
};
