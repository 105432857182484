import React, { FC } from 'react';
import { Link } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { ActiveLink } from '../ui/links/ActiveLink';
import { ProfileMenu } from './ProfileMenu';
import { RiDiscussLine, RiMenuLine } from 'react-icons/ri';
import { Badge } from '../ui/label/Badge';
import { EColor } from '../../common/colors';

interface IProps {}

const LINK_CLASS_NAME =
  'ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition-colors duration-150';
const LINK_ACTIVE_CLASS_NAME =
  'ml-4 px-3 py-2 rounded-md text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700';

export const Header: FC<IProps> = () => {
  return (
    <nav className='bg-gray-800'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-16'>
          <Link to={APP_PATHS.MAIN} className='text-white text-2xl flex items-center flex-shrink-0'>
            <img
              alt='Electrolyse'
              src={require('../../img/logo-white.svg')}
              className='h-10 mr-4'
              style={{ marginLeft: -2 }}
            />
            <Badge color={EColor.Teal}>beta</Badge>
          </Link>
          <div className='flex-grow flex items-center justify-end'>
            <ActiveLink to={APP_PATHS.MAIN} className={LINK_CLASS_NAME} activeClassName={LINK_ACTIVE_CLASS_NAME}>
              Dashboard
            </ActiveLink>
            <ActiveLink to={APP_PATHS.PROJECTS} className={LINK_CLASS_NAME} activeClassName={LINK_ACTIVE_CLASS_NAME}>
              Projects
            </ActiveLink>
            <ActiveLink to={APP_PATHS.EVENTS} className={LINK_CLASS_NAME} activeClassName={LINK_ACTIVE_CLASS_NAME}>
              Events
            </ActiveLink>
            <ActiveLink to={APP_PATHS.STATISTICS} className={LINK_CLASS_NAME} activeClassName={LINK_ACTIVE_CLASS_NAME}>
              Statistics
            </ActiveLink>
          </div>
          <Link to={''} className='text-gray-300 hover:text-white ml-6 transition-colors duration-150 relative'>
            <i className='h-2 w-2 bg-red-600 absolute rounded-full gradient-white' style={{ top: -1, right: -3.5 }} />
            <RiDiscussLine className='w-6 h-6' />
          </Link>
          <ProfileMenu />
          <Link to={''} className='text-gray-300 hover:text-white ml-6 transition-colors duration-150'>
            <RiMenuLine className='w-6 h-6' />
          </Link>
        </div>
      </div>
    </nav>
  );
};
