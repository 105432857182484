import React, { FC, ReactNode } from 'react';
import { RiFolderOpenLine } from 'react-icons/ri';

interface IProps {
  icon?: ReactNode;
}

export const NothingHere: FC<IProps> = ({ children, icon = <RiFolderOpenLine className='h-10 w-10' /> }) => {
  return (
    <div className='p-12 flex flex-col items-center justify-center text-gray-500 text-sm'>
      <div className='h-20 w-20 bg-blue-50 text-blue-400 transition-colors duration-200 rounded-full flex items-center justify-center'>
        {icon}
      </div>
      <div className='mt-4 text-center'>{children}</div>
    </div>
  );
};
