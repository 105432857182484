import axios from 'axios';
import { API_PATHS } from '../../electrolyse-shared/api-paths';
import { useParseFieldErrors } from './useParseFieldErrors';
import { IErrorField, IInvite } from '../../electrolyse-shared/interfaces';

export const useInvites = () => {
  const parseFieldErrors = useParseFieldErrors();

  const getInvite = (code: string) => {
    return axios.get<{ item: IInvite }>(API_PATHS.GET_INVITE_BY_CODE.replace(':code', code));
  };

  const acceptInvite = (code: string) => {
    return axios.post<{ item: IInvite }>(API_PATHS.POST_ACCEPT_INVITE.replace(':code', code));
  };

  const getProjectInvites = async (projectId: string) => {
    try {
      const result = await axios.get<{ items: IInvite[] }>(API_PATHS.GET_PROJECT_INVITES.replace(':id', projectId));
      return result.data.items;
    } catch (e) {
      return [];
    }
  };

  const newInvite = async (
    email: string,
    projectId: string,
  ): Promise<{
    item?: IInvite;
    error?: string;
    fields: IErrorField[];
  }> => {
    try {
      const result = await axios.put<{ item: IInvite }>(API_PATHS.PUT_NEW_INVITE, { email, projectId });
      return {
        item: result.data.item,
        error: undefined,
        fields: [],
      };
    } catch (e) {
      return {
        item: undefined,
        error: e.response?.data?.error,
        fields: parseFieldErrors(e),
      };
    }
  };

  return {
    acceptInvite,
    getInvite,
    newInvite,
    getProjectInvites,
  };
};
