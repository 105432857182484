export enum EPlatform {
  MacOS = 'macOS',
  Windows = 'Windows',
  Linux = 'Linux',
}

export enum EStatus {
  Inactive = 'Inactive',
  Active = 'Active',
  Invalid = 'Invalid',
  Archived = 'Archived',
}

export enum EChannel {
  Alpha = 'Alpha',
  Beta = 'Beta',
  Latest = 'Latest',
}

export const PLATFORM_URL_MATCH = {
  [EPlatform.Linux]: 'linux',
  [EPlatform.MacOS]: 'mac',
  [EPlatform.Windows]: 'windows',
};

export const CHANNEL_URL_MATCH = {
  [EChannel.Alpha]: 'alpha',
  [EChannel.Beta]: 'beta',
  [EChannel.Latest]: 'latest',
};

export enum EReleaseFileState {
  Empty,
  Processing,
  Stored,
  Error,
}

export enum EReleaseFileType {
  Update = 'update',
  Installer = 'installer',
  BlockMap = 'blockMap',
}

export enum EStatType {
  AppStarted,
  CheckUpdateRequested,
  DownloadUpdateRequested,
  DownloadInstallerRequested,
  DownloadUpdateCompleted,
  UpdateInstalled,
}

export interface IReleaseFile {
  _id: string;
  platform: EPlatform;
  type: EReleaseFileType;
  size: number;
  url: string;
  key: string;
  sha512: string;
  blockMapSize: number;
  state: EReleaseFileState;
}

export interface IRelease {
  _id?: string;
  project?: IProject;
  version?: string;
  date: Date;
  files: IReleaseFile[];
  status: EStatus;
  channel: EChannel;
  distribution: number;
  minor?: number;
  major?: number;
  patch?: number;
  justCreated: boolean;
  releaseNotes: string;
  user?: IUser;
}

export interface IErrorField {
  name: string;
  errors: string[];
}

export interface IProject {
  _id?: string;
  name?: string;
  avatar?: string;
  description?: string;
  releases: IRelease[];
  user?: IUser;
}

export interface IUser {
  _id: string;
  nickName: string;
  email?: string;
  password?: string;
  passwordHash?: string;
}

export interface IInvite {
  _id: string;
  email: string;
  active: boolean;
  date: Date;
  project: IProject;
  inviter: IUser;
  invitee?: IUser;
  acceptCode?: string;
}

export interface IStatistic {
  _id: string;
  type: EStatType;
  ip: string;
  platform: EPlatform;
  channel: EChannel;
  fileType: EReleaseFileType;
  version: string;
  project: IProject;
  date: Date;
  country: string; // 2 letter ISO-3166-1 country code
}

export interface ISession {
  _id: string;
  ip: string;
  platform: EPlatform;
  channel: EChannel;
  version: string;
  project: IProject;
  country: string;
  lastSignalDate: Date;
}

export interface IProjectStatsAggregated {
  _id: string;
  count: number;
  percentage: number;
}

export interface IProjectStatsAggregatedCountry extends IProjectStatsAggregated {
  countryName: string;
}

export interface IProjectStatsAggregatedPlatform extends IProjectStatsAggregated {
  platform: EPlatform;
}

export interface IProjectStats {
  totalAppStarts: number;
  totalAppStartsToday: number;
  totalAppStartsYesterday: number;
  totalAppStartsChange: number;
  totalUpdateDownloads: number;
  totalUpdateDownloadRequestedToday: number;
  totalUpdateDownloadRequestedYesterday: number;
  totalUpdateDownloadChange: number;
  totalInstallerDownloads: number;
  totalInstallerDownloadRequestedToday: number;
  totalInstallerDownloadRequestedYesterday: number;
  totalInstallerDownloadChange: number;
  platformInstallerStats: IProjectStatsAggregatedPlatform[];
  countryInstallerStats: IProjectStatsAggregatedCountry[];
  platformUpdateStats: IProjectStatsAggregatedPlatform[];
  countryUpdateStats: IProjectStatsAggregatedCountry[];
  currentOnline: number;
}
