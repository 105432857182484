import React, { FC } from 'react';
import { EPlatform, IReleaseFile } from '../../../../electrolyse-shared/interfaces';
import { FaApple, FaLinux, FaWindows } from 'react-icons/fa';

interface IProps {
  files: IReleaseFile[];
}

export const Platforms: FC<IProps> = ({ files }) => {
  if (!files || files.length === 0) {
    return <div className='flex items-center text-sm text-gray-500'>No files</div>;
  }

  const windows = files.find(f => f.platform === EPlatform.Windows);
  const macOs = files.find(f => f.platform === EPlatform.MacOS);
  const linux = files.find(f => f.platform === EPlatform.Linux);

  return (
    <div className='flex'>
      <div className='flex items-center text-sm text-gray-500 bg-gray-100 h-7 px-1 rounded-full'>
        {windows && <FaWindows title={EPlatform.Windows} className='mx-1' />}
        {macOs && <FaApple title={EPlatform.MacOS} className='mx-1' />}
        {linux && <FaLinux title={EPlatform.Linux} className='mx-1' />}
      </div>
    </div>
  );
};
