import { ENV } from './env';
import { EChannel, EStatus, IProject, IRelease } from '../../electrolyse-shared/interfaces';

export const DEFAULTS = {
  COOKIE_OPTIONS: {
    domain: `.${ENV.BASE_DOMAIN}`,
    path: '/',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 10)),
  },

  PORTAL_ROOT_SELECTORS: {
    MODALS: '#modals-root',
    DROPDOWNS: '#dropdowns-root',
    NOTIFICATIONS: '#notifications-root',
  },
};

export const DEFAULT_RELEASE: IRelease = {
  _id: undefined,
  project: undefined,
  version: undefined,
  date: new Date(),
  distribution: 0,
  status: EStatus.Inactive,
  channel: EChannel.Alpha,
  files: [],
  minor: undefined,
  major: undefined,
  patch: undefined,
  justCreated: true,
  releaseNotes: '',
  user: undefined,
};

export const DEFAULT_PROJECT: IProject = {
  _id: undefined,
  name: undefined,
  description: undefined,
  avatar: undefined,
  releases: [],
  user: undefined,
};
