import React, { FC, Fragment, useContext, useState } from 'react';
import { IconContainer } from '../icons/IconContainer';
import { EPartialStyle } from '../../../enums/EPartialStyle';
import { RiUserAddLine } from 'react-icons/ri';
import { Button, EButtonStyle } from '../button/Button';
import { IProject, IErrorField } from '../../../../electrolyse-shared/interfaces';
import { IModalProps } from '../../../hooks/useModal';
import { useForm } from 'react-hook-form';
import { Input } from '../form/Input';
import { useLoading } from '../../../hooks/useLoading';
import { useInvites } from '../../../hooks/useInvites';
import { NotificationsContext } from '../notification/Notifications';

interface IModel {
  email: string;
}

interface IProps extends IModalProps {
  project: IProject | null;
  onSuccess: () => void;
}

export const InviteModal: FC<IProps> = ({ project, handleClose, onSuccess }) => {
  const notificationsContext = useContext(NotificationsContext);
  const { register, handleSubmit } = useForm<IModel>();
  const [errorFields, setErrorFields] = useState<IErrorField[]>([]);
  const { setLocalLoading, localLoading } = useLoading();
  const { newInvite } = useInvites();

  const submit = async (data: IModel) => {
    setLocalLoading(true);
    const result = await newInvite(data.email, project?._id ?? '');
    setErrorFields(result?.fields ?? []);
    setLocalLoading(false);
    if (result.item) {
      handleClose();
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'User invited',
        title: 'Success',
      });
      onSuccess();
    } else if (result.error) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: result.error,
        title: 'Error',
      });
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(submit)}>
        <div
          className='bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all'
          style={{ width: 350 }}>
          <div>
            <IconContainer
              iconStyle={EPartialStyle.Accent}
              renderIcon={className => <RiUserAddLine className={className} />}
            />
            <div className='mt-3 text-center sm:mt-5'>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>Invite new member to project</h3>
              <div className='text-gray-500 text-sm'>{project?.name}</div>
              <div className='mt-6'>
                <div className='text-sm leading-5 text-gray-500'>
                  <Input
                    errorFields={errorFields}
                    attrs={{ name: 'email', placeholder: 'Invitee email', autoFocus: true }}
                    ref={register}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='mt-5 flex justify-center'>
            <Button disabled={localLoading} attrs={{ type: 'submit' }} style={EButtonStyle.Accent}>
              Send invite
            </Button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};
