import React, { createContext, FC, useState } from 'react';
import { IBreadcrumbItem } from '../components/global/Breadcrumbs';
import { IUser } from '../../electrolyse-shared/interfaces';

interface IAppProviderContext {
  setLoading: (loading: boolean) => void;
  setUser: (user: IUser | null) => void;
  setLocalLoading: (loading: boolean) => void;
  setGlobalLoading: (loading: boolean) => void;
  user: IUser | null;
  breadcrumbs: IBreadcrumbItem[];
  setBreadcrumbs: (breadcrumbs: IBreadcrumbItem[]) => void;
  loading: boolean;
  localLoading: boolean;
  globalLoading: boolean;
}

export const AppProviderContext = createContext<IAppProviderContext>({
  setLoading: () => {},
  setUser: () => {},
  setLocalLoading: () => {},
  setGlobalLoading: () => {},
  setBreadcrumbs: () => {},
  user: null,
  breadcrumbs: [],
  loading: true,
  localLoading: false,
  globalLoading: false,
});

export const AppProvider: FC = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [globalLoading, setGlobalLoadingState] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumbItem[]>([]);

  const setGlobalLoading = (value: boolean) => {
    const container = document.getElementById('global-loading');
    if (container) {
      container.className = `${container.className.replace(' active', value ? ' active' : '')}`;
      setGlobalLoadingState(value);
      setTimeout(() => {
        if (value) {
          container.className = `${container.className.replace(' hide', '')}`;
        } else {
          container.className = `${container.className} hide`;
        }
      }, 510);
    }
  };

  return (
    <AppProviderContext.Provider
      value={{
        user,
        setUser,
        loading,
        localLoading,
        setLoading,
        setLocalLoading,
        setGlobalLoading,
        setBreadcrumbs,
        breadcrumbs,
        globalLoading,
      }}>
      {children}
    </AppProviderContext.Provider>
  );
};
