import React, { FC, useState, Fragment } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { IProject } from '../../../electrolyse-shared/interfaces';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import axios from 'axios';
import { API_PATHS } from '../../../electrolyse-shared/api-paths';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { Button, EButtonStyle } from '../ui/button/Button';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { ContentWrapper } from '../global/ContentWrapper';
import { ScreenLayout } from '../global/ScreenLayout';
import { ProjectsList } from '../global/ProjectsList';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { useLoading } from '../../hooks/useLoading';
import { NothingHere } from '../global/NothingHere';
import { Skeleton } from '../ui/loading/Skeleton';

interface IProps extends RouteComponentProps {}

export const ProjectsScreen: FC<IProps> = () => {
  const { setLoading, loading } = useLoading();
  const [projects, setProjects] = useState<IProject[]>([]);
  const { setBreadcrumbs } = useBreadcrumbs();
  const navigate = useNavigate();

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const result = await axios.get<{ items: IProject[] }>(API_PATHS.GET_PROJECTS);
      setProjects(result.data.items);
      setBreadcrumbs([{ title: 'Projects', path: APP_PATHS.PROJECTS }]);
    } catch (e) {}
    setLoading(false);
  }, []);

  return (
    <ScreenLayout
      title={<Breadcrumbs />}
      button={
        <div>
          <Button
            style={EButtonStyle.Accent}
            attrs={{
              onClick: () => navigate(APP_PATHS.NEW_PROJECT),
            }}>
            New project
          </Button>
        </div>
      }>
      <ContentWrapper>
        {loading ? (
          <Skeleton />
        ) : (
          <Fragment>
            {projects.length > 0 ? (
              <ProjectsList projects={projects} />
            ) : (
              <NothingHere>You have no projects</NothingHere>
            )}
          </Fragment>
        )}
      </ContentWrapper>
    </ScreenLayout>
  );
};
