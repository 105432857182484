import React, { FC, useContext, useState } from 'react';
import { IProject, IRelease } from '../../../electrolyse-shared/interfaces';
import { Button, EButtonStyle } from '../ui/button/Button';
import { DEFAULT_PROJECT } from '../../common/defaults';
import axios from 'axios';
import { API_PATHS } from '../../../electrolyse-shared/api-paths';
import { NotificationsContext } from '../ui/notification/Notifications';
import { useNavigate } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { ErrorFields } from '../ui/alert/ErrorFields';
import { useParseFieldErrors } from '../../hooks/useParseFieldErrors';
import { useModal } from '../../hooks/useModal';
import { DangerModal } from '../ui/modal/DangerModal';
import { ConfirmModal } from '../ui/modal/ConfirmModal';
import { useGetProject } from '../../hooks/useGetProject';
import { useLoading } from '../../hooks/useLoading';
import { EPartialStyle } from '../../enums/EPartialStyle';

interface IProps {
  project?: IProject;
}

export const Project: FC<IProps> = ({ project: initialProject = DEFAULT_PROJECT }) => {
  const { setLoading } = useLoading();
  const getErrors = useParseFieldErrors();
  const navigate = useNavigate();
  const notificationsContext = useContext(NotificationsContext);
  const [project, setProject] = useState(initialProject);
  const { getProjectById } = useGetProject();
  const commitChangesModal = useModal(
    ({ handleClose }) => (
      <ConfirmModal
        {...{
          handleClose,
          title: 'Commit changes?',
          agreeText: 'Commit',
          cancelText: 'Cancel',
          handleAgree: () => commitChanges(),
        }}>
        xxx
      </ConfirmModal>
    ),
    { onEnter: () => commitChanges() },
  );
  const deleteModal = useModal(
    ({ handleClose }) => (
      <DangerModal
        {...{
          handleClose,
          title: 'Are you sure want to delete this release?',
          agreeText: 'Commit',
          cancelText: 'Cancel',
          handleAgree: () => deleteProject(),
        }}>
        Are you sure want to delete this release?
      </DangerModal>
    ),
    {
      onEnter: () => deleteProject(),
    },
  );

  const commitChanges = async () => {
    commitChangesModal.close();
    setLoading(true);
    try {
      const result = await axios.patch<{ item: IProject }>(
        API_PATHS.PATCH_RELEASE.replace(':id', project?._id || ''),
        project,
      );
      setProject(result.data.item);
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Project updated',
        title: 'Success',
      });
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: <ErrorFields fields={getErrors(e)} />,
        title: e.response?.data?.error,
      });
    }
    setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    const result = await getProjectById(project?._id || '');
    if (result) {
      setProject(result);
    }
    setLoading(false);
  };

  const createProject = async () => {
    setLoading(true);
    try {
      const result = await axios.put<{ item: IRelease }>(API_PATHS.PUT_NEW_PROJECT, project);
      if (result.data.item._id) {
        await navigate(APP_PATHS.PROJECT.replace(':id', result.data.item._id));
      }
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Project created',
        title: 'Success',
      });
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: <ErrorFields fields={getErrors(e)} />,
        title: e.response?.data?.error,
      });
    }
    setLoading(false);
  };

  const deleteProject = async () => {
    setLoading(true);
    try {
      await axios.delete(API_PATHS.DELETE_RELEASE.replace(':id', project?._id || ''));
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: 'Project deleted',
        title: 'Success',
      });
      await navigate(APP_PATHS.MAIN);
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: e.response?.data?.error,
        title: 'Error',
      });
    }
    setLoading(false);
    deleteModal.close();
  };

  const isNew = project._id === DEFAULT_PROJECT._id;

  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-lg my-2'>
      <div className='px-4 py-5 border-b border-gray-200 sm:px-6 flex justify-end'>
        {isNew ? (
          <div className='flex items-center'>
            <div className='max-w-7xl ml-6'>
              <Button
                style={EButtonStyle.Accent}
                attrs={{
                  onClick: () => createProject(),
                }}>
                Create project
              </Button>
            </div>
          </div>
        ) : (
          <div className='flex items-center'>
            {!isNew && (
              <div className='max-w-7xl ml-6'>
                <Button
                  style={EButtonStyle.Default}
                  attrs={{
                    onClick: () => deleteModal.open(),
                  }}>
                  Delete
                </Button>
              </div>
            )}
            <div className='max-w-7xl ml-6'>
              <Button
                style={EButtonStyle.Accent}
                attrs={{
                  onClick: () => commitChangesModal.open(),
                }}>
                Save changes
              </Button>
            </div>
          </div>
        )}
      </div>
      <div>
        <dl>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500 items-center flex'>Project name</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <input
                placeholder='New project'
                className='rounded-md px-4 py-2 w-full border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150'
                value={project.name || ''}
                onChange={event => {
                  setProject({
                    ...project,
                    name: event.target.value,
                  });
                }}
              />
            </dd>
          </div>
          <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
            <dt className='text-sm leading-5 font-medium text-gray-500'>Project description</dt>
            <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
              <textarea
                value={project.description || ''}
                onChange={event => {
                  setProject({
                    ...project,
                    description: event.target.value,
                  });
                }}
                className='rounded-md px-4 py-2 w-full h-32 border border-gray-300 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150'
              />
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
