import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Release } from '../global/Release';
import { IProject, IRelease } from '../../../electrolyse-shared/interfaces';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { useGetRelease } from '../../hooks/useGetRelease';
import { NotFound } from '../global/NotFound';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { useGetProject } from '../../hooks/useGetProject';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { useLoading } from '../../hooks/useLoading';

interface IProps extends RouteComponentProps {
  id?: string;
  projectId?: string;
}

export const ReleaseScreen: FC<IProps> = ({ id, projectId }) => {
  const { setLoading, loading } = useLoading();
  const [release, setRelease] = useState<IRelease | null>(null);
  const [project, setProject] = useState<IProject | null>(null);
  const { getProjectById } = useGetProject();
  const { getReleaseById } = useGetRelease();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [notFound, setNotFound] = useState(false);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const project = await getProjectById(projectId ?? '');
      const release = await getReleaseById(id ?? '');
      setProject(project);
      setRelease(release);
      setBreadcrumbs([
        { title: 'Projects', path: APP_PATHS.PROJECTS },
        {
          title: project.name,
          path: APP_PATHS.PROJECT.replace(':id', projectId ?? ''),
        },
        {
          title: 'Releases',
          path: APP_PATHS.PROJECT_RELEASES.replace(':id', projectId ?? ''),
        },
        {
          title: release.version,
          path: APP_PATHS.RELEASE.replace(':projectId', projectId ?? '').replace(':id', id ?? ''),
        },
      ]);
      if (!project || !release) {
        setNotFound(true);
      }
      return setLoading(false);
    } catch (e) {}
    setNotFound(true);
    setLoading(false);
  }, []);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <ScreenLayout title={<Breadcrumbs />}>
      {!loading && project && release && <Release project={project} release={release} />}
    </ScreenLayout>
  );
};
