import React, { FC, useState } from 'react';
import { IProject, IProjectStats } from '../../../electrolyse-shared/interfaces';
import { useGetProjectStats } from '../../hooks/useGetProjectStats';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { Changes } from '../ui/label/Changes';

interface IProps {
  project: IProject | null;
}

export const ProjectInfo: FC<IProps> = ({ project }) => {
  const { getAll } = useGetProjectStats();
  const [stats, setStats] = useState<IProjectStats>({
    totalInstallerDownloadRequestedToday: 0,
    totalUpdateDownloadRequestedToday: 0,
    totalInstallerDownloadChange: 0,
    totalUpdateDownloadChange: 0,
    totalInstallerDownloadRequestedYesterday: 0,
    totalUpdateDownloadRequestedYesterday: 0,
    totalInstallerDownloads: 0,
    totalUpdateDownloads: 0,
    countryInstallerStats: [],
    platformInstallerStats: [],
    countryUpdateStats: [],
    platformUpdateStats: [],
    totalAppStarts: 0,
    totalAppStartsChange: 0,
    totalAppStartsToday: 0,
    totalAppStartsYesterday: 0,
    currentOnline: 0,
  });

  useAsyncEffect(async () => {
    if (project?._id) {
      setStats(await getAll(project._id));
    }
  }, []);

  return (
    <div>
      <div className='mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow md:grid-cols-4'>
        <div>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-base leading-6 font-normal text-gray-800'>Download requests</dt>
              <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                <div className='flex items-baseline text-2xl leading-8 font-semibold text-indigo-600'>
                  {stats.totalInstallerDownloadRequestedToday}
                  <span className='ml-2 text-sm leading-5 font-medium text-gray-500'>
                    today of {stats.totalInstallerDownloads} total
                  </span>
                </div>
                <Changes value={stats.totalInstallerDownloadChange} />
              </dd>
            </dl>
          </div>
        </div>
        <div className='border-t border-gray-200 md:border-0 md:border-l'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-base leading-6 font-normal text-gray-800'>Update requests</dt>
              <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                <div className='flex items-baseline text-2xl leading-8 font-semibold text-indigo-600'>
                  {stats.totalUpdateDownloadRequestedToday}
                  <span className='ml-2 text-sm leading-5 font-medium text-gray-500'>
                    today of {stats.totalUpdateDownloads} total
                  </span>
                </div>
                <Changes value={stats.totalUpdateDownloadChange} />
              </dd>
            </dl>
          </div>
        </div>
        <div className='border-t border-gray-200 md:border-0 md:border-l'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-base leading-6 font-normal text-gray-800'>App starts</dt>
              <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                <div className='flex items-baseline text-2xl leading-8 font-semibold text-indigo-600'>
                  {stats.totalAppStartsToday}
                  <span className='ml-2 text-sm leading-5 font-medium text-gray-500'>
                    today of {stats.totalAppStarts} total
                  </span>
                </div>
                <Changes value={stats.totalAppStartsChange} />
              </dd>
            </dl>
          </div>
        </div>
        <div className='border-t border-gray-200 md:border-0 md:border-l'>
          <div className='px-4 py-5 sm:p-6'>
            <dl>
              <dt className='text-base leading-6 font-normal text-gray-800'>Current online</dt>
              <dd className='mt-1 flex justify-between items-baseline md:block lg:flex'>
                <div className='flex items-baseline text-2xl leading-8 font-semibold text-indigo-600'>
                  {stats.currentOnline}
                  <span className='ml-2 text-sm leading-5 font-medium text-gray-500'>for minute</span>
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      <div className='flex flex-col mt-6'>
        <div className='-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
          <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200'>
            <table className='min-w-full'>
              <thead>
                <tr>
                  <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                    Country
                  </th>
                  <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                    Position
                  </th>

                  <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                    Count
                  </th>
                  <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
                    Percentage
                  </th>
                </tr>
              </thead>
              <tbody className='bg-white'>
                {stats.countryInstallerStats.map((item, index) => (
                  <tr key={item._id}>
                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-800 font-medium'>
                      {item._id ? (
                        <span className='flex items-center'>
                          <span>
                            <img
                              src={`/flags/${item._id.toLowerCase()}.svg`}
                              width='22'
                              className='block rounded-sm shadow-xs mr-2'
                              alt={item.countryName}
                            />
                          </span>
                          {item.countryName}
                        </span>
                      ) : (
                        'Unknown'
                      )}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                      {index + 1}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                      {item.count}
                    </td>
                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                      {item.percentage.toLocaleString('en', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })}
                      %
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
