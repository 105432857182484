import React, { FC, Fragment, ReactNode } from 'react';
import { IModalProps } from '../../../hooks/useModal';
import { RiQuestionLine } from 'react-icons/ri';

interface IProps extends IModalProps {
  title: ReactNode;
  agreeText: ReactNode;
  cancelText: ReactNode;
  handleAgree: () => void;
}

export const ConfirmModal: FC<IProps> = ({ children, handleClose, title, agreeText, handleAgree, cancelText }) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow-xl transform sm:max-w-lg sm:w-full">
      <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
        <div className='sm:flex sm:items-start'>
          <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10'>
            <RiQuestionLine className="h-6 w-6 text-blue-600" />
          </div>
          <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>{title}</h3>
            <div className='mt-2'>
              <div className='text-sm leading-5 text-gray-500'>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse'>
        <span className='flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto'>
          <button
            onClick={handleAgree}
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
            {agreeText}
          </button>
        </span>
        <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto'>
          <button
            onClick={handleClose}
            type='button'
            className='inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5'>
            {cancelText}
          </button>
        </span>
      </div>
    </div>
  );
};
