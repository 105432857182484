import axios from 'axios';
import { IRelease } from '../../electrolyse-shared/interfaces';
import { API_PATHS } from '../../electrolyse-shared/api-paths';

export const useGetRelease = () => {
  return {
    getReleaseById: async (id: string) => {
      return (await axios.get<{ item: IRelease }>(API_PATHS.GET_RELEASE.replace(':id', id))).data.item;
    },
  };
};
