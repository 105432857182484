import { IErrorField } from '../../electrolyse-shared/interfaces';

export const useParseFieldErrors = () => {
  return (error: any) => {
    if (error?.response?.data?.fields && error.response.data.fields.length > 0) {
      const fields: IErrorField[] = error.response.data.fields.map((f: any) => {
        const errors = [];
        for (const key in f.constraints) {
          if (f.constraints.hasOwnProperty(key)) {
            errors.push(f.constraints[key]);
          }
        }
        return {
          name: f.property,
          errors,
        };
      });
      return fields;
    }
    return [];
  };
};
