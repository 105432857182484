import { EChannel } from '../../electrolyse-shared/interfaces';
import { EColor } from '../common/colors';

export const useChannelToColor = () => {
  return (status?: EChannel): EColor => {
    switch (status) {
      case EChannel.Latest: {
        return EColor.Teal;
      }
      case EChannel.Beta: {
        return EColor.Blue;
      }
      default: {
        return EColor.Gray;
      }
    }
  };
};
