import React, { FC, Fragment, useContext, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayoutMinimal } from '../global/ScreenLayoutMinimal';
import { Auth } from '../global/Auth';
import { FormRow } from '../ui/form/FormRow';
import { Button, EButtonStyle } from '../ui/button/Button';
import { useLoading } from '../../hooks/useLoading';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { useInvites } from '../../hooks/useInvites';
import { IInvite } from '../../../electrolyse-shared/interfaces';
import { UserLinkLabel } from '../ui/label/UserLinkLabel';
import { useAuth } from '../../hooks/useAuth';
import { NotificationsContext } from '../ui/notification/Notifications';
import { IconContainer } from '../ui/icons/IconContainer';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { EPartialStyle } from '../../enums/EPartialStyle';

interface IModel {}

interface IProps extends RouteComponentProps {
  code?: string;
}

export const InviteScreen: FC<IProps> = ({ code }) => {
  const notificationsContext = useContext(NotificationsContext);
  const { setLoading, setLocalLoading, localLoading } = useLoading();
  const [invite, setInvite] = useState<IInvite | null>(null);
  const { getInvite, acceptInvite } = useInvites();
  const { checkAuth } = useAuth();
  const [error, setError] = useState(undefined);
  const [errorType, setErrorType] = useState<'alreadyAccepted' | 'error' | undefined>(undefined);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const inviteItem = await getInvite(code ?? '');
      setInvite(inviteItem.data.item);
    } catch (e) {
      setErrorType(e.response.status === 410 ? 'alreadyAccepted' : 'error');
      setError(e.response?.data?.error);
    }
    setLoading(false);
  }, []);

  const onSubmit = async () => {
    setLocalLoading(true);
    const auth = await checkAuth(false, false);
    if (auth) {
      try {
        const acceptedInvite = await acceptInvite(code ?? '');
        console.log(acceptedInvite);
      } catch (e) {
        notificationsContext.addNotification({
          type: EPartialStyle.Danger,
          body: e.response?.data?.error,
          title: 'Error',
        });
      }
    }
    setLocalLoading(false);
  };

  return (
    <ScreenLayoutMinimal privatePath={false} redirectOnAuth={false}>
      <Auth>
        {error && (
          <div className='flex flex-col items-center justify-center'>
            <IconContainer
              iconStyle={errorType === 'alreadyAccepted' ? EPartialStyle.Success : EPartialStyle.Danger}
              renderIcon={className =>
                errorType === 'alreadyAccepted' ? (
                  <RiCheckLine className={className} />
                ) : (
                  <RiCloseLine className={className} />
                )
              }
            />
            <div className='mt-4'>{error}</div>
          </div>
        )}
        {invite && (
          <Fragment>
            <div className='flex flex-col items-center justify-center text-center'>
              <UserLinkLabel to='' name={invite.inviter.nickName} />
              <h2 className='mt-4'>
                Has invited you to collaborate on a project
                <br />
                <span className='whitespace-no-wrap font-bold'>{invite.project.name}!</span>
              </h2>
            </div>
            <FormRow type='button'>
              <Button
                disabled={localLoading}
                style={EButtonStyle.Accent}
                size='large'
                attrs={{
                  type: 'submit',
                  onClick: () => onSubmit(),
                }}>
                Accept invite
              </Button>
            </FormRow>
          </Fragment>
        )}
      </Auth>
    </ScreenLayoutMinimal>
  );
};
