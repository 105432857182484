import React, { FC } from 'react';
import { TabsNav } from '../ui/tabs/TabsNav';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';

interface IProps {
  projectId: string;
}

export const ProjectNav: FC<IProps> = ({ projectId }) => {
  const nav = [
    {
      title: 'Project',
      path: APP_PATHS.PROJECT.replace(':id', projectId),
    },
    {
      title: 'Releases',
      path: APP_PATHS.PROJECT_RELEASES.replace(':id', projectId),
    },
    {
      title: 'Members',
      path: APP_PATHS.PROJECT_INVITE.replace(':id', projectId),
    },
    {
      title: 'Integration',
      path: APP_PATHS.PROJECT_INTEGRATION.replace(':id', projectId),
    },
    {
      title: 'Statistics',
      path: APP_PATHS.PROJECT_STATS.replace(':id', projectId),
    },
    {
      title: 'Settings',
      path: APP_PATHS.PROJECT_SETTINGS.replace(':id', projectId),
    },
  ];

  return (
    <div className='mb-2 -mt-8'>
      <TabsNav items={nav} />
    </div>
  );
};
