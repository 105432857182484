import React, { FC, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { Release } from '../global/Release';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { IProject } from '../../../electrolyse-shared/interfaces';
import { useGetProject } from '../../hooks/useGetProject';
import { NotFound } from '../global/NotFound';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { useLoading } from '../../hooks/useLoading';

interface IProps extends RouteComponentProps {
  id?: string;
}

export const NewReleaseScreen: FC<IProps> = ({ id }) => {
  const { setLoading, loading } = useLoading();
  const [project, setProject] = useState<IProject | null>(null);
  const { getProjectById } = useGetProject();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [notFound, setNotFound] = useState(false);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const project = await getProjectById(id ?? '');
      setProject(project);
      setBreadcrumbs([
        { title: 'Projects', path: APP_PATHS.PROJECTS },
        { title: project.name, path: APP_PATHS.PROJECT.replace(':id', id ?? '') },
        { title: 'New release', path: APP_PATHS.NEW_RELEASE.replace(':id', id ?? '') },
      ]);
      if (!project) {
        setNotFound(true);
      }
      return setLoading(false);
    } catch (e) {}
    setLoading(false);
    setNotFound(true);
  }, []);

  if (notFound) {
    return <NotFound />;
  }

  return <ScreenLayout title={<Breadcrumbs />}>{!loading && project && <Release project={project} />}</ScreenLayout>;
};
