import React, { FC } from 'react';
import classNames from 'classnames';
import { EColor } from '../../../common/colors';

interface IProps {
  color: EColor;
  dot?: boolean;
  onRemove?: () => void;
  size?: 'small' | 'large';
  rounded?: boolean;
}

export const Badge: FC<IProps> = ({ color, dot, onRemove, size = 'small', rounded, children }) => {
  const className = classNames(`inline-flex items-center font-medium bg-${color}-100 text-${color}-800`, {
    'px-2.5 py-0.5 text-xs leading-4': size === 'small',
    'px-3 py-0.5 text-sm leading-5': size === 'large',
    'rounded-md': size === 'large' && rounded,
    'rounded': size === 'small' && rounded,
    'rounded-full': !rounded,
  });

  return (
    <span className={className}>
      {dot && (
        <svg className={`-ml-0.5 mr-1.5 h-2 w-2 text-${color}-400`} fill='currentColor' viewBox='0 0 8 8'>
          <circle cx='4' cy='4' r='3' />
        </svg>
      )}
      {children}
      {onRemove && (
        <button
          type='button'
          className='flex-shrink-0 -mr-0.5 ml-1.5 inline-flex text-indigo-500 focus:outline-none focus:text-indigo-700'>
          <svg className={`h-2 w-2 text-${color}-500`} stroke='currentColor' fill='none' viewBox='0 0 8 8'>
            <path strokeLinecap='round' strokeWidth='1.5' d='M1 1l6 6m0-6L1 7' />
          </svg>
        </button>
      )}
    </span>
  );
};
