import React, { FC } from 'react';
import { IErrorField } from '../../../../electrolyse-shared/interfaces';

interface IProps {
  fields: IErrorField[];
}

export const ErrorFields: FC<IProps> = ({ fields }) => {
  return (
    <div>
      {fields.map(f => (
        <ul key={f.name}>{f.errors?.[0]}</ul>
      ))}
    </div>
  );
};
