import React, { LegacyRef, InputHTMLAttributes, forwardRef, useState, useEffect } from 'react';
import { ErrorFields } from '../alert/ErrorFields';
import { IErrorField } from '../../../../electrolyse-shared/interfaces';
import classNames from 'classnames';

interface IProps {
  attrs: InputHTMLAttributes<HTMLInputElement>;
  errorFields?: IErrorField[];
}

export const Input = forwardRef((props: IProps, ref: LegacyRef<HTMLInputElement>) => {
  const { attrs, errorFields = [] } = props;
  const errorFieldsFiltered = errorFields.filter(f => f.name === attrs.name);

  return (
    <div className='relative'>
      <input
        ref={ref}
        className={classNames(
          'bg-white focus:outline-none focus:shadow-outline border  rounded-md py-2 px-3 block w-full appearance-none leading-normal',
          {
            'border-red-400': errorFieldsFiltered.length > 0,
            'border-gray-300': errorFieldsFiltered.length === 0,
          },
        )}
        {...attrs}
      />
      <div className='absolute text-sm right-0 text-red-500' style={{ bottom: '100%', transform: 'translateY(-20%)' }}>
        <ErrorFields fields={errorFieldsFiltered.filter(f => f.name === attrs.name)} />
      </div>
    </div>
  );
});
