import React, { FC, Fragment } from 'react';
import { IProject } from '../../../electrolyse-shared/interfaces';
import classnames from 'classnames';
import { Link } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Avatar } from '../ui/avatar/Avatar';
import { useStatusToColor } from '../../hooks/useStatusToColor';
import { Badge } from '../ui/label/Badge';
import { RiArrowRightSLine } from 'react-icons/ri';
import { useChannelToColor } from '../../hooks/useChannelToColor';
import { UserLinkLabel } from '../ui/label/UserLinkLabel';
import { useUser } from '../../hooks/useUser';
import { CreatedBy } from './CreatedBy';

interface IProps {
  projects: IProject[];
}

export const ProjectsList: FC<IProps> = ({ projects }) => {
  const getStatusColor = useStatusToColor();
  const getChannelColor = useChannelToColor();

  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-md'>
      <ul>
        {projects.map((project, index) => {
          const latestRelease = project.releases?.[0] ? project.releases[0] : undefined;
          const status = latestRelease ? latestRelease.status : undefined;
          const channel = latestRelease ? latestRelease.channel : undefined;

          return (
            <li
              key={project._id}
              className={classnames({
                'border-t border-gray-200': index > 0,
              })}>
              <Link
                to={APP_PATHS.PROJECT.replace(':id', project?._id ?? '')}
                className='block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out'>
                <div className='flex items-center px-4 py-4 sm:px-6'>
                  <div className='min-w-0 flex-1 flex items-center'>
                    <div className='flex-shrink-0'>
                      <Avatar words={project?.name ?? ''} />
                    </div>
                    <div className='min-w-0 flex-1 px-4'>
                      <div className='flex flex-col justify-center'>
                        <div className='text-sm leading-5 font-medium text-blue-600 truncate h-5'>{project.name}</div>
                        <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                          {latestRelease ? (
                            <Fragment>
                              <span className='mr-4'>
                                <Badge size='large' rounded dot color={getStatusColor(status)}>
                                  {status}
                                </Badge>
                              </span>
                              <span className='mr-4'>
                                <Badge size='large' rounded dot color={getChannelColor(channel)}>
                                  {channel}
                                </Badge>
                              </span>
                              Created by&nbsp;
                              <CreatedBy outerUser={project.user} />
                            </Fragment>
                          ) : (
                            <span className='mr-4'>Not released</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <RiArrowRightSLine className='h-6 w-6 text-gray-400' />
                  </div>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
