import React, { FC, Fragment } from 'react';
import { RouteComponentProps } from '@reach/router';
import { FormRow } from '../ui/form/FormRow';
import { Input } from '../ui/form/Input';
import { FormLabel } from '../ui/label/FormLabel';
import { Anchor } from '../ui/misc/Anchor';
import { Auth } from '../global/Auth';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Button, EButtonStyle } from '../ui/button/Button';
import { ScreenLayoutMinimal } from '../global/ScreenLayoutMinimal';

interface IProps extends RouteComponentProps {}

export const RememberPasswordScreen: FC<IProps> = () => {
  return (
    <ScreenLayoutMinimal privatePath={false}>
      <Auth
        subtitle={
          <Fragment>
            Or sign in&nbsp;<Anchor to={APP_PATHS.AUTH_LOGIN}>to your account</Anchor>
          </Fragment>
        }>
        <form action=''>
          <FormRow>
            <FormLabel attrs={{ htmlFor: 'email' }}>Email</FormLabel>
            <Input attrs={{ id: 'email', autoComplete: 'email', autoFocus: true }} />
          </FormRow>
          <FormRow type='button'>
            <Button
              style={EButtonStyle.Accent}
              size='large'
              attrs={{
                type: 'submit',
              }}>
              Send instructions
            </Button>
          </FormRow>
          <FormRow type='text'>Settle your email and we will send you instructions on how to change password</FormRow>
        </form>
      </Auth>
    </ScreenLayoutMinimal>
  );
};
