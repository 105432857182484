import { API_PATHS } from '../../electrolyse-shared/api-paths';
import { useParseFieldErrors } from './useParseFieldErrors';
import { EStorageName } from '../common/storage';
import { useLocation, useNavigate } from '@reach/router';
import { APP_PATHS } from '../../electrolyse-shared/app-paths';
import { useLoading } from './useLoading';
import axios from 'axios';
import { IUser } from '../../electrolyse-shared/interfaces';
import { useUser } from './useUser';

export const useAuth = () => {
  const { setUser } = useUser();
  const parseFieldErrors = useParseFieldErrors();
  const navigate = useNavigate();
  const location = useLocation();
  const { setGlobalLoading } = useLoading();

  const setToken = (token: string | null) => {
    if (token) {
      localStorage.setItem(EStorageName.Token, token);
    } else {
      localStorage.removeItem(EStorageName.Token);
    }
  };

  const getToken = () => {
    return localStorage.getItem(EStorageName.Token);
  };

  const logOut = async () => {
    setToken(null);
    setUser(null);
    setGlobalLoading(true);
    await navigate(APP_PATHS.AUTH_LOGIN);
  };

  const checkAuth = (privatePath: boolean, redirectOnAuth: boolean = true) => {
    return new Promise<boolean>(async (resolve, reject) => {
      const redirectPath = privatePath ? APP_PATHS.AUTH_LOGIN : APP_PATHS.MAIN;
      let auth = Boolean(getToken());

      if (auth) {
        try {
          const result = await axios.get<{ user: IUser }>(API_PATHS.GET_ME);
          setUser(result.data.user);
        } catch (e) {
          auth = false;
        }
      }

      if (redirectOnAuth && redirectPath !== location.pathname) {
        if (privatePath) {
          if (!auth) {
            await navigate(redirectPath);
          }
        } else {
          if (auth) {
            await navigate(redirectPath);
          }
        }
      }

      resolve(auth);
    });
  };

  const redirectSuccessAuth = async () => {
    await navigate(APP_PATHS.MAIN);
  };

  const signIn = async (email: string, password: string) => {
    try {
      const result = await axios.post<{ token: string }>(API_PATHS.POST_LOGIN, {
        email,
        password,
      });
      setToken(result.data.token);
      await redirectSuccessAuth();
      return {
        error: null,
        fields: [],
      };
    } catch (e) {
      return {
        error: e.response?.status !== 400 ? e.response?.data.error : undefined,
        fields: parseFieldErrors(e),
      };
    }
  };

  const signUp = async (email: string, password: string) => {
    try {
      const result = await axios.put<{ token: string }>(API_PATHS.PUT_REGISTER, {
        email,
        password,
      });
      setToken(result.data.token);
      await redirectSuccessAuth();
      return {
        error: null,
        fields: [],
      };
    } catch (e) {
      return {
        error: e.response?.status !== 400 ? e.response?.data.error : undefined,
        fields: parseFieldErrors(e),
      };
    }
  };

  return {
    checkAuth,
    signIn,
    signUp,
    logOut,
  };
};
