import React, { ChangeEvent, FC, Fragment, useContext, useRef, useState } from 'react';
import { IModalProps } from '../../../hooks/useModal';
import { RiCheckboxCircleLine, RiSpam2Line, RiUploadCloud2Line } from 'react-icons/ri';
import classNames from 'classnames';
import axios from 'axios';
import { API_PATHS } from '../../../../electrolyse-shared/api-paths';
import { EPlatform, IRelease, EReleaseFileType } from '../../../../electrolyse-shared/interfaces';
import filesize from 'filesize';
import { NotificationsContext } from '../notification/Notifications';
import { FileLogo } from '../../global/FileLogo';
import { Button, EButtonStyle } from '../button/Button';
import { EPartialStyle } from '../../../enums/EPartialStyle';

interface IProps extends IModalProps {
  release: IRelease;
  platform: EPlatform;
  type: EReleaseFileType;
  extension: string;
  onUpdate: () => void;
}

enum EUploadStage {
  Idle,
  FileSelected,
  Uploading,
  Processing,
  Completed,
  Error,
}

export const UploadModal: FC<IProps> = ({ release, platform, type, extension, handleClose, onUpdate }) => {
  const notificationsContext = useContext(NotificationsContext);
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [stage, setStage] = useState(EUploadStage.Idle);
  const [percent, setPercent] = useState(0);

  const toggleInput = () => {
    if (
      inputRef.current &&
      (stage === EUploadStage.Idle || stage === EUploadStage.Completed || stage === EUploadStage.Error)
    ) {
      inputRef.current.click();
    }
  };

  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0] ?? null;
    setStage(file ? EUploadStage.FileSelected : EUploadStage.Idle);
    setFile(file);
  };

  const upload = async () => {
    if (file) {
      setStage(EUploadStage.Uploading);
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('platform', platform);
        formData.append('type', type);
        await axios.post(
          API_PATHS.POST_RELEASE_UPLOAD.replace(':id', release?._id ?? '').replace(':platform', platform),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setPercent(percentCompleted);
              if (percentCompleted === 100) {
                setStage(EUploadStage.Processing);
              }
            },
          },
        );
        setStage(EUploadStage.Completed);
        onUpdate();
      } catch (e) {
        notificationsContext.addNotification({
          type: EPartialStyle.Danger,
          body: e.response?.data?.error,
          title: 'Error',
        });
        setStage(EUploadStage.Error);
        onUpdate();
      }
      setFile(null);
      setPercent(0);
    }
  };

  return (
    <Fragment>
      <div className='bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6'>
        <div>
          <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100'>
            <FileLogo platform={platform} noText />
          </div>
          <div className='mt-2 text-center sm:mt-3'>
            <h3 className='text-lg leading-6 mb-1 font-medium text-gray-900'>Upload {platform} update</h3>
            <div className='mt-2'>
              <p className='text-sm leading-5 text-gray-500'>
                Select corresponding update file for selected operating system
              </p>
              <div className='text-sm leading-5 text-gray-500'>
                <div
                  onClick={toggleInput}
                  className={classNames('h-48 border-2 transition-all p8 duration-200 mt-4 rounded-lg relative', {
                    'border-dashed border-blue-200 hover:bg-blue-50 cursor-pointer':
                      stage === EUploadStage.Idle || stage === EUploadStage.FileSelected,
                    'border-dashed border-blue-200':
                      stage === EUploadStage.Uploading || stage === EUploadStage.Processing,
                    'border-green-200 cursor-pointer hover:bg-blue-50 hover:border-blue-200 bg-green-50':
                      stage === EUploadStage.Completed,
                    'border-red-200 bg-red-50 relative cursor-pointer hover:bg-blue-50 hover:border-blue-200':
                      stage === EUploadStage.Error,
                  })}>
                  <i className='absolute h-full w-full bg-blue-100 top-0 left-0' style={{ width: `${percent}%` }} />
                  <div className='h-full w-full flex flex-col items-center justify-center text-center rounded-lg absolute top-0 left-0'>
                    <div className='flex justify-center items-center w-14 h-14 mb-2'>
                      {(stage === EUploadStage.Idle || stage === EUploadStage.FileSelected) && (
                        <RiUploadCloud2Line className='h-full w-full text-blue-400' />
                      )}
                      {stage === EUploadStage.Uploading && (
                        <span className='text-blue-400'>Uploading&nbsp;{percent}%</span>
                      )}
                      {stage === EUploadStage.Processing && <span className='text-blue-400'>Processing...</span>}
                      {stage === EUploadStage.Completed && (
                        <RiCheckboxCircleLine className='h-full w-full text-green-300' />
                      )}
                      {stage === EUploadStage.Error && <RiSpam2Line className='h-full w-full text-red-300' />}
                    </div>
                    {stage !== EUploadStage.Completed && stage !== EUploadStage.Error && (
                      <div className='px-6'>{file ? file.name : 'Select file'}</div>
                    )}
                    {stage === EUploadStage.Completed && <div className='px-6'>Completed</div>}
                    {stage === EUploadStage.Error && <div className='px-6'>Error</div>}
                    <div className='text-gray-400 text-xs'>
                      {stage === EUploadStage.Error ? (
                        <Fragment>Try to select another file</Fragment>
                      ) : (
                        <Fragment>{file && filesize(file.size)}</Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <input
                  ref={inputRef}
                  multiple={false}
                  accept={extension}
                  className='opacity-0 absolute pointer-events-none'
                  type='file'
                  name='file'
                  onChange={onChangeFile}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense'>
          <span className='flex w-full rounded-md shadow-sm sm:col-start-2'>
            <Button
              attrs={{
                onClick: upload,
              }}
              style={EButtonStyle.Accent}
              disabled={
                !file ||
                stage === EUploadStage.Uploading ||
                stage === EUploadStage.Processing ||
                stage === EUploadStage.Completed ||
                stage === EUploadStage.Idle
              }>
              Upload
            </Button>
          </span>
          <span className='mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1'>
            <Button
              attrs={{
                onClick: handleClose,
              }}
              style={EButtonStyle.Default}>
              {stage === EUploadStage.Completed ? 'OK' : 'Cancel'}
            </Button>
          </span>
        </div>
      </div>
    </Fragment>
  );
};
