import React, { FC, ReactNode } from 'react';
import { Disclaimer } from '../ui/typography/Disclaimer';
import { Card } from '../ui/card/Card';
import { Link } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';

interface IProps {
  subtitle?: ReactNode;
}

export const Auth: FC<IProps> = ({ subtitle, children }) => {
  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50 text-gray-800'>
      <div style={{ maxWidth: 420, width: '100%' }}>
        <div className='flex justify-center mb-8'>
          <Link to={APP_PATHS.MAIN}>
            <img src={require('../../img/logo-dark.svg')} className='h-14' alt='Electrolyse' />
          </Link>
        </div>
        <Card>
          {subtitle && (
            <div className='mb-8 text-center'>
              <div className='flex justify-center mt-1 text-gray-600'>{subtitle}</div>
            </div>
          )}
          {children}
        </Card>
        <div className='text-center mt-8'>
          <Disclaimer>
            <p>Telemetry Arts, Inc. &copy; 2020</p>
          </Disclaimer>
        </div>
      </div>
    </div>
  );
};
