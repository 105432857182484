import React, { FC } from 'react';

export const ContentWrapper: FC = ({ children }) => {
  return (
    <div className='flex flex-col'>
      <div className='py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8'>
        <div className='align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200 bg-white'>
          {children}
        </div>
      </div>
    </div>
  );
};
