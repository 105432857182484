import React, { FC, ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';

export enum EButtonStyle {
  Default,
  Accent,
  Danger,
}

interface IProps {
  attrs?: ButtonHTMLAttributes<HTMLButtonElement>;
  style?: EButtonStyle;
  disabled?: boolean;
  size?: 'small' | 'large';
}

export const Button: FC<IProps> = ({
  attrs = {},
  style = EButtonStyle.Default,
  disabled = false,
  size = 'small',
  children,
}) => {
  return (
    <button
      {...attrs}
      disabled={disabled}
      className={classnames(
        'inline-flex justify-center w-full rounded-md border text-base font-medium shadow-sm focus:outline-none transition ease-in-out duration-150',
        {
          'border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:border-blue-300 focus:shadow-outline':
            style === EButtonStyle.Default,
          'border-transparent bg-red-600 text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-red':
            style === EButtonStyle.Danger,
          'border-transparent bg-blue-600 text-white hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue':
            style === EButtonStyle.Accent,
          'opacity-50 pointer-events-none': disabled,
          'px-4 py-2 sm:text-sm leading-6 sm:leading-5': size === 'small',
          'px-8 py-3 sm:text-base leading-8 sm:leading-7': size === 'large',
        },
      )}>
      {children}
    </button>
  );
};
