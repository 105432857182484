import React, { FC } from 'react';
import './Skeleton.css';

interface IProps {}

export const Skeleton: FC<IProps> = () => {
  return (
    <div className='overflow-hidden relative'>
      <div className='skeleton m-6'>
        <img src={require('../../../img/skeleton.svg')} />
      </div>
    </div>
  );
};
