import React, { FC, ReactNode } from 'react';

import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from '@reach/router';
import classnames from 'classnames';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';

export interface IBreadcrumbItem {
  title: ReactNode;
  path: string;
}

interface IProps {}

export const Breadcrumbs: FC<IProps> = () => {
  const { breadcrumbs } = useBreadcrumbs();
  return (
    <span className='flex items-center text-xl relative'>
      {breadcrumbs.map((breadcrumb, index) => {
        const last = index === breadcrumbs.length - 1;
        return (
          <Link
            to={breadcrumb.path}
            key={breadcrumb.path}
            className={classnames('flex items-end', {
              'hover:text-blue-700 transition-colors duration-150 text-gray-500': !last,
              'mr-4 text-gray-800': last,
            })}>
            {breadcrumb.title}
            {!last && (
              <RiArrowRightSLine
                className='text-gray-400 mx-2 relative'
                style={{
                  top: -1,
                }}
              />
            )}
          </Link>
        );
      })}
    </span>
  );
};
