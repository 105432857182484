import React, { FC } from 'react';
import { Routes } from './global/Routes';
import { Notifications } from './ui/notification/Notifications';
import { Modals } from './ui/modal/Modals';
import { DEFAULTS } from '../common/defaults';
import { AppProvider } from '../providers/AppProvider';

export const App: FC = () => {
  return (
    <AppProvider>
      <Notifications allowIdentical rootContainerSelector={DEFAULTS.PORTAL_ROOT_SELECTORS.NOTIFICATIONS}>
        <Modals portalRootSelector={'#modals-root'} avoidOnClickRootSelector={''}>
          <Routes />
        </Modals>
      </Notifications>
    </AppProvider>
  );
};
