import React, { FC, Fragment } from 'react';
import { Avatar, EAvatarSize } from '../avatar/Avatar';
import { Link } from '@reach/router';
import classNames from 'classnames';

interface IProps {
  name: string;
  imgSrc?: string;
  words?: string;
  to?: string;
  onClick?: () => void;
}

export const UserLinkLabel: FC<IProps> = ({ to, onClick = () => {}, name, words, imgSrc }) => {
  const className = 'pl-0.5 pr-3 bg-gray-100 text-gray-800 h-7 inline-flex text-sm items-center rounded-full transition-color duration-150';
  const content = (
    <Fragment>
      <Avatar size={EAvatarSize.Xs} words={words ? words : name} imgSrc={imgSrc} />
      <span className='ml-2'>{name}</span>
    </Fragment>
  );
  if (to) {
    return (
      <Link to={to} onClick={onClick} className={classNames(className, 'hover:bg-gray-200')}>
        {content}
      </Link>
    );
  } else {
    return <span className={className}>{content}</span>;
  }
};
