import { useContext, useRef, useCallback, ReactNode, useState } from 'react';
import { IModal, IModalSharedOptions, ModalsContext } from '../components/ui/modal/Modals';

export interface IModalProps {
  handleClose: () => void;
}

const DEFAULT_OPTIONS: IModalSharedOptions = {
  showOverlay: true,
  closeByOutsideClick: true,
  closeByEscapeKey: true,
};

export const useModal = (renderModalComponent: (props: IModalProps) => ReactNode, options = DEFAULT_OPTIONS) => {
  const [currentModal, setCurrentModal] = useState<IModal | null>(null);
  const modalContext = useContext(ModalsContext);
  const closeHandler = useRef(() => {});
  const close = useCallback(() => {
    if (closeHandler.current) {
      closeHandler.current();
    }
  }, []);

  const open = useCallback(() => {
    if (!currentModal || !currentModal.isOpened) {
      setCurrentModal(
        modalContext.openModal({
          renderModalComponent: id => {
            closeHandler.current = () => modalContext.closeModal(id);
            return renderModalComponent({
              handleClose: closeHandler.current,
            });
          },
          ...options,
        }),
      );
    }
  }, [renderModalComponent, options]);

  return {
    open,
    close,
  };
};
