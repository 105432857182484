import axios from 'axios';
import { IProjectStats } from '../../electrolyse-shared/interfaces';
import { API_PATHS } from '../../electrolyse-shared/api-paths';

export const useGetProjectStats = () => {
  return {
    getAll: async (id: string) => {
      return (await axios.get<{ stats: IProjectStats }>(API_PATHS.GET_PROJECT_STATS.replace(':id', id))).data.stats;
    },
  };
};
