import React, { FC, useEffect } from 'react';
import { RiFileForbidLine } from 'react-icons/ri';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Link } from '@reach/router';
import { Breadcrumbs } from './Breadcrumbs';
import { ContentWrapper } from './ContentWrapper';
import { ScreenLayout } from './ScreenLayout';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';

interface IProps {}

export const NotFound: FC<IProps> = () => {
  const { setBreadcrumbs, breadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs(
      breadcrumbs.concat([
        {
          title: 'Not found',
          path: '',
        },
      ]),
    );
  }, []);

  return (
    <ScreenLayout title={<Breadcrumbs />}>
      <ContentWrapper>
        <div className='p-12 flex flex-col items-center justify-center text-gray-500 text-sm'>
          <Link
            to={APP_PATHS.MAIN}
            className='h-20 w-20 bg-blue-50 hover:bg-blue-100 text-blue-400 hover:text-blue-500 transition-colors duration-200 rounded-full flex items-center justify-center'>
            <RiFileForbidLine className='h-10 w-10' />
          </Link>
          <div className='mt-4 text-center'>
            Item not found
            <br />
            Return to&nbsp;
            <Link
              to={APP_PATHS.MAIN}
              className='font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out'>
              the main page
            </Link>
          </div>
        </div>
      </ContentWrapper>
    </ScreenLayout>
  );
};
