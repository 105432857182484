import React, { FC, LinkHTMLAttributes } from 'react';
import { Link } from '@reach/router';

interface IProps {
  to: string;
}

export const Anchor: FC<IProps> = ({ to, children }) => {
  return (
    <Link to={to} className='text-blue-600 hover:text-blue-500 font-medium'>
      {children}
    </Link>
  );
};
