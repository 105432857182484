import React, { FC } from 'react';
import { Link, useNavigate } from '@reach/router';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Platforms } from '../ui/misc/Platforms';
import { Badge } from '../ui/label/Badge';
import { IProject, IRelease, EReleaseFileType } from '../../../electrolyse-shared/interfaces';
import { useStatusToColor } from '../../hooks/useStatusToColor';
import { formatDistanceToNow } from 'date-fns';
import { useChannelToColor } from '../../hooks/useChannelToColor';
import { RiArrowRightSLine } from 'react-icons/ri';
import { CreatedBy } from './CreatedBy';

interface IProps {
  releases: IRelease[];
  project?: IProject;
}

export const ReleasesList: FC<IProps> = ({ releases }) => {
  const navigate = useNavigate();
  const getStatusBadgeColor = useStatusToColor();
  const getChannelBadgeColor = useChannelToColor();

  return (
    <table className='min-w-full'>
      <thead>
        <tr>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Version
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Status
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Channel
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Distribution
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Date
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Created by
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Update files
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider'>
            Installer files
          </th>
          <th className='px-6 py-3 border-b border-gray-200 bg-gray-50' />
        </tr>
      </thead>
      <tbody className='bg-white'>
        {releases.map(release => {
          const url = APP_PATHS.RELEASE.replace(':projectId', release?.project?._id ?? '').replace(
            ':id',
            release?._id ?? '',
          );
          return (
            <tr
              key={release._id}
              className='hover:bg-gray-50 focus:outline-none focus:bg-gray-50 cursor-pointer transition duration-150 ease-in-out'
              onClick={() => navigate(url)}>
              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <div className='flex items-center'>
                  <div>
                    <div className='text-sm leading-5 font-medium text-gray-900'>
                      <Link to={url} className='text-blue-600 hover:text-blue-800 flex justify-end items-center'>
                        {release.version}
                      </Link>
                    </div>
                  </div>
                </div>
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <Badge dot rounded size='large' color={getStatusBadgeColor(release.status)}>
                  {release.status}
                </Badge>
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <Badge dot rounded size='large' color={getChannelBadgeColor(release.channel)}>
                  {release.channel}
                </Badge>
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <div className='text-sm leading-5 text-gray-500'>{release.distribution}%</div>
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                {formatDistanceToNow(new Date(release.date))}
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500'>
                <CreatedBy outerUser={release.user} />
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <Platforms files={release.files.filter(file => file.type === EReleaseFileType.Update)} />
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                <Platforms files={release.files.filter(file => file.type === EReleaseFileType.Installer)} />
              </td>

              <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-right'>
                <RiArrowRightSLine className='h-6 w-6 text-gray-400 inline-block' />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
