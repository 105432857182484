import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { ContentWrapper } from '../global/ContentWrapper';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Skeleton } from '../ui/loading/Skeleton';
import { useLoading } from '../../hooks/useLoading';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { IProject } from '../../../electrolyse-shared/interfaces';
import { useGetProject } from '../../hooks/useGetProject';
import { NotFound } from '../global/NotFound';
import { ProjectNav } from '../global/ProjectNav';

interface IProps extends RouteComponentProps {
  id?: string;
}

export const ProjectSettingsScreen: FC<IProps> = ({ id }) => {
  const { setLoading, loading } = useLoading();
  const [project, setProject] = useState<IProject | null>(null);
  const { getProjectById } = useGetProject();
  const { setBreadcrumbs } = useBreadcrumbs();
  const [notFound, setNotFound] = useState(false);

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const project = await getProjectById(id ?? '');
      setProject(project);
      setBreadcrumbs([
        { title: 'Projects', path: APP_PATHS.PROJECTS },
        {
          title: project.name,
          path: APP_PATHS.PROJECT.replace(':id', id ?? ''),
        },
        {
          title: 'Settings (under construction)',
          path: APP_PATHS.PROJECT_SETTINGS.replace(':id', id ?? ''),
        },
      ]);
      if (!project) {
        setNotFound(true);
      }
      return setLoading(false);
    } catch (e) {}
    setNotFound(true);
    setLoading(false);
  }, []);

  if (notFound) {
    return <NotFound />;
  }

  return (
    <ScreenLayout title={<Breadcrumbs />}>
      <ProjectNav projectId={id ?? ''} />
      {!loading && project && (
        <ContentWrapper>
          <Skeleton />
        </ContentWrapper>
      )}
    </ScreenLayout>
  );
};
