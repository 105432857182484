import React, { FC, Fragment } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useLoading } from '../../hooks/useLoading';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';
import { Loading } from './Loading';

interface IProps {
  privatePath?: boolean;
  redirectOnAuth?: boolean;
}

export const ScreenLayoutMinimal: FC<IProps> = ({ privatePath = true, redirectOnAuth = true, children }) => {
  const { checkAuth } = useAuth();
  const { globalLoading, setGlobalLoading } = useLoading();

  useAsyncEffect(async () => {
    await checkAuth(privatePath, redirectOnAuth);
    setGlobalLoading(false);
  }, []);

  if (globalLoading) {
    return null;
  }

  return (
    <Fragment>
      {children}
      <Loading />
    </Fragment>
  );
};
