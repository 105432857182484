import React, { FC, useContext, Fragment, useRef, useEffect, useState } from 'react';
import { INotification, NotificationsContext } from './Notifications';
import { CSSTransition } from 'react-transition-group';
import {
  RiLoaderLine,
  RiErrorWarningLine,
  RiInformationLine,
  RiChat1Line,
  RiCheckboxCircleLine,
  RiCloseLine,
} from 'react-icons/ri';
import classNames from 'classnames';
import { EPartialStyle } from '../../../enums/EPartialStyle';

interface IProps {
  notification: INotification;
  animationTime: number;
  width: number;
  onDelete: () => void;
}

export const Notification: FC<IProps> = ({ notification, animationTime, width, onDelete }) => {
  const ref = useRef<HTMLDivElement>(null);
  const { removeNotification } = useContext(NotificationsContext);
  const iconClassName = classNames('h-6 w-6', {
    'text-indigo-600': notification.type === EPartialStyle.Accent,
    'text-red-600': notification.type === EPartialStyle.Danger,
    'text-blue-500': notification.type === EPartialStyle.Info,
    'text-gray-400': notification.type === EPartialStyle.Default,
    'text-green-400': notification.type === EPartialStyle.Success,
  });
  const customIcon = notification.renderIcon ? notification.renderIcon(iconClassName) : undefined;
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setHeight(notification.show ? ref.current.getBoundingClientRect().height : 0);
    }
  }, [notification.show]);

  return (
    <div className='relative'>
      <div style={{ height, width }} className='transition-all duration-200' />
      <CSSTransition
        timeout={animationTime}
        in={notification.show}
        key={notification.id}
        unmountOnExit
        nodeRef={ref}
        classNames={{
          enter: 'opacity-0 scale-90 transform',
          enterActive: `ease-out duration-${animationTime} opacity-100 scale-100 transform`,
          enterDone: 'opacity-100 scale-100 transform',
          exitActive: `ease-in duration-${animationTime} opacity-0 scale-90 transform`,
        }}
        onEntered={() => {
          if (ref.current) {
            setHeight(notification.show ? ref.current.getBoundingClientRect().height : 0);
          }
        }}
        onExited={() => onDelete()}>
        <div className='max-w-sm w-full absolute top-0' ref={ref}>
          <div className='bg-white shadow-xl mt-4 rounded-lg pointer-events-auto'>
            <div className='rounded-lg overflow-hidden'>
              <div className='p-4'>
                <div className='flex items-start'>
                  {(!notification.renderIcon || (notification.renderIcon && customIcon)) && (
                    <div className='flex-shrink-0 mr-3'>
                      {notification.renderIcon ? (
                        <Fragment>{customIcon}</Fragment>
                      ) : (
                        <Fragment>
                          {notification.type === EPartialStyle.Accent && <RiLoaderLine className={iconClassName} />}
                          {notification.type === EPartialStyle.Danger && (
                            <RiErrorWarningLine className={iconClassName} />
                          )}
                          {notification.type === EPartialStyle.Info && <RiInformationLine className={iconClassName} />}
                          {notification.type === EPartialStyle.Default && <RiChat1Line className={iconClassName} />}
                          {notification.type === EPartialStyle.Success && (
                            <RiCheckboxCircleLine className={iconClassName} />
                          )}
                        </Fragment>
                      )}
                    </div>
                  )}
                  <div className='w-0 flex-1 pt-0.5 ml-1'>
                    {notification.title && (
                      <p className='text-sm leading-5 font-medium text-gray-900'>{notification.title}</p>
                    )}
                    <p className='mt-1 text-sm leading-5 text-gray-500'>{notification.body}</p>
                  </div>
                  <div className='ml-4 flex-shrink-0 flex'>
                    <button
                      onClick={() => {
                        removeNotification(notification.id);
                      }}
                      className='inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150'>
                      <RiCloseLine className='h-6 w-6' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
