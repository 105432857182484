import React, { FC, Fragment } from 'react';
import { IInvite } from '../../../electrolyse-shared/interfaces';
import { format } from 'date-fns';
import classnames from 'classnames';
import { RiUserFollowLine, RiMailLine, RiArrowRightSLine, RiTimeLine } from 'react-icons/ri';
import { Avatar } from '../ui/avatar/Avatar';

interface IProps {
  invites: IInvite[];
}

export const InvitesList: FC<IProps> = ({ invites }) => {
  return (
    <div className='bg-white shadow overflow-hidden sm:rounded-md'>
      <ul>
        {invites.map((invite, index) => {
          return (
            <li
              key={invite._id}
              className={classnames({
                'border-t border-gray-200': index > 0,
              })}>
              <a
                href='#'
                className='block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out'>
                <div className='flex items-center px-4 py-4 sm:px-6'>
                  <div className='min-w-0 flex-1 flex items-center'>
                    <div className='flex-shrink-0'>
                      <Avatar words={invite.invitee?.nickName || invite.email} />
                    </div>
                    <div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
                      <div>
                        <div className='text-sm leading-5 font-medium text-blue-600 truncate'>
                          {invite.invitee?.nickName || 'Unregistered user'}
                        </div>
                        <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                          <RiMailLine className='flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400' />
                          <span className='truncate'>{invite.email}</span>
                        </div>
                      </div>
                      <div className='hidden md:block'>
                        <div>
                          <div className='text-sm leading-5 text-gray-900'>
                            Invited on&nbsp;
                            <time>{format(new Date(invite.date), 'MMM d, yyyy H:mm:ss')}</time>
                          </div>
                          <div className='mt-2 flex items-center text-sm leading-5 text-gray-500'>
                            {invite.active ? (
                              <Fragment>
                                <RiUserFollowLine className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400' />
                                Invite accepted
                              </Fragment>
                            ) : (
                              <Fragment>
                                <RiTimeLine className='h-5 w-5 mr-1.5 text-gray-400' />
                                Awaiting user
                              </Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <RiArrowRightSLine className='h-6 w-6 text-gray-400' />
                  </div>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
