import React, { FC, ReactNode } from 'react';
import { EPartialStyle } from '../../../enums/EPartialStyle';
import { usePartialStyle } from '../../../hooks/usePartialStyle';

interface IProps {
  iconStyle: EPartialStyle;
  renderIcon: (className: string) => ReactNode;
}

export const IconContainer: FC<IProps> = ({ iconStyle, renderIcon }) => {
  const partialStyleIconBg = usePartialStyle(
    iconStyle,
    {
      [EPartialStyle.Default]: 'bg-gray-100',
      [EPartialStyle.Success]: 'bg-green-100',
      [EPartialStyle.Danger]: 'bg-red-100',
      [EPartialStyle.Accent]: 'bg-indigo-100',
      [EPartialStyle.Info]: 'bg-blue-100',
    },
    'mx-auto flex items-center justify-center h-12 w-12 rounded-full',
  );

  const partialStyleIconText = usePartialStyle(
    iconStyle,
    {
      [EPartialStyle.Default]: 'text-gray-400',
      [EPartialStyle.Success]: 'text-green-400',
      [EPartialStyle.Danger]: 'text-red-400',
      [EPartialStyle.Accent]: 'text-indigo-400',
      [EPartialStyle.Info]: 'text-blue-400',
    },
    'h-6 w-6',
  );

  return <div className={partialStyleIconBg}>{renderIcon(partialStyleIconText)}</div>;
};
