import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ScreenLayout } from '../global/ScreenLayout';
import { ContentWrapper } from '../global/ContentWrapper';
import { Breadcrumbs } from '../global/Breadcrumbs';
import { useBreadcrumbs } from '../../hooks/useBreadcrumbs';
import { APP_PATHS } from '../../../electrolyse-shared/app-paths';
import { Skeleton } from '../ui/loading/Skeleton';
import { useLoading } from '../../hooks/useLoading';
import { useAuth } from '../../hooks/useAuth';
import { useAsyncEffect } from '../../hooks/useAsyncEffect';

interface IProps extends RouteComponentProps {}

export const EventsScreen: FC<IProps> = () => {
  const { setLoading } = useLoading();
  const { setBreadcrumbs } = useBreadcrumbs();

  useAsyncEffect(() => {
    setBreadcrumbs([{ title: 'Events (under construction)', path: APP_PATHS.MAIN }]);
    setLoading(false);
  }, []);

  return (
    <ScreenLayout title={<Breadcrumbs />}>
      <ContentWrapper>
        <Skeleton />
      </ContentWrapper>
    </ScreenLayout>
  );
};
