import React, { FC, ReactNode } from 'react';
import { IRelease } from '../../../electrolyse-shared/interfaces';
import { RiArrowRightLine } from 'react-icons/ri';
import { useStatusToColor } from '../../hooks/useStatusToColor';
import { Badge } from '../ui/label/Badge';
import { useChannelToColor } from '../../hooks/useChannelToColor';

interface IProps {
  releaseOne: IRelease;
  releaseTwo: IRelease;
}

interface IPropsCompare {
  title: string;
  propName: keyof IRelease;
  releaseOne: IRelease;
  releaseTwo: IRelease;
  renderValue: (value: any) => ReactNode;
}

const Compare: FC<IPropsCompare> = ({ title, propName, releaseOne, releaseTwo, renderValue }) => {
  if (releaseOne[propName] === releaseTwo[propName]) {
    return null;
  }
  return (
    <div className='w-full mt-6 mb-4 min-w-full'>
      <div className='text-gray-500 mb-1'>{title}</div>
      <div className='flex items-center w-full'>
        {renderValue(releaseOne[propName])}
        <RiArrowRightLine className='mx-2' />
        {renderValue(releaseTwo[propName])}
      </div>
    </div>
  );
};

export const ReleaseCompare: FC<IProps> = ({ releaseOne, releaseTwo }) => {
  const getStatusBadgeColor = useStatusToColor();
  const getChannelBadgeColor = useChannelToColor();

  if (JSON.stringify(releaseOne) === JSON.stringify(releaseTwo)) {
    return <div>Nothing was changed</div>;
  }

  return (
    <div className='text-gray-700 text-sm w-full'>
      <Compare
        title='Distribution'
        propName='distribution'
        releaseOne={releaseOne}
        releaseTwo={releaseTwo}
        renderValue={value => <strong>{value}%</strong>}
      />
      <Compare
        title='Status'
        propName='status'
        releaseOne={releaseOne}
        releaseTwo={releaseTwo}
        renderValue={value => <Badge color={getStatusBadgeColor(value)}>{value}</Badge>}
      />
      <Compare
        title='Channel'
        propName='channel'
        releaseOne={releaseOne}
        releaseTwo={releaseTwo}
        renderValue={value => <Badge color={getChannelBadgeColor(value)}>{value}</Badge>}
      />
      <Compare
        title='Release Notes'
        propName='releaseNotes'
        releaseOne={releaseOne}
        releaseTwo={releaseTwo}
        renderValue={value => <div>{value}</div>}
      />
    </div>
  );
};
