import { EPartialStyle } from '../enums/EPartialStyle';
import classnames from 'classnames';

interface IPartialStyle {
  [EPartialStyle.Default]: string;
  [EPartialStyle.Danger]: string;
  [EPartialStyle.Accent]: string;
  [EPartialStyle.Success]: string;
  [EPartialStyle.Info]: string;
}

export const usePartialStyle = (partialStyle: EPartialStyle, styles: IPartialStyle, baseClass: string = '') => {
  return classnames(baseClass, {
    [styles[EPartialStyle.Default]]: partialStyle === EPartialStyle.Default,
    [styles[EPartialStyle.Success]]: partialStyle === EPartialStyle.Success,
    [styles[EPartialStyle.Danger]]: partialStyle === EPartialStyle.Danger,
    [styles[EPartialStyle.Accent]]: partialStyle === EPartialStyle.Accent,
    [styles[EPartialStyle.Info]]: partialStyle === EPartialStyle.Info,
  });
};
