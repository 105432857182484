import React, { FC, useMemo } from 'react';
import classNames from 'classnames';

export enum EAvatarSize {
  Xs,
  Sm,
  Md,
  Lg,
  Xl,
}

interface IProps {
  words: string;
  delimiters?: RegExp;
  size?: EAvatarSize;
  imgSrc?: string;
}

export const Avatar: FC<IProps> = ({ words, delimiters = /[\s.-]+/, size = EAvatarSize.Lg, imgSrc }) => {
  const label = useMemo(() => {
    if (!words) {
      return '';
    }
    const wordsSplit = words.split(delimiters);
    if (wordsSplit.length >= 2 && wordsSplit[0].length >= 1 && wordsSplit[1].length >= 1) {
      return `${wordsSplit[0].substr(0, 1)}${wordsSplit[1].substr(0, 1)}`;
    } else if (wordsSplit.length === 1 && wordsSplit[0].length >= 2) {
      return `${wordsSplit[0].substr(0, 1)}${wordsSplit[0].substr(1, 1)}`;
    } else if (wordsSplit.length === 1 && wordsSplit[0].length === 1) {
      return `${wordsSplit[0].length}`;
    } else {
      return '';
    }
  }, [words]);

  const sizeClass = {
    'h-6 w-6': size === EAvatarSize.Xs,
    'h-8 w-8': size === EAvatarSize.Sm,
    'h-10 w-10': size === EAvatarSize.Md,
    'h-12 w-12': size === EAvatarSize.Lg,
    'h-14 w-14': size === EAvatarSize.Xl,
  };

  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center rounded-full relative overflow-hidden bg-gray-400 gradient-white',
        sizeClass,
      )}
      style={{
        backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3))',
      }}>
      {imgSrc ? (
        <img src={imgSrc} alt={words} className={classNames('object-cover flex-shrink-0', sizeClass)} />
      ) : (
        <span
          className={classNames(
            'leading-none flex-shrink-0 text-white uppercase flex items-center justify-center leading-none',
            sizeClass,
            {
              'text-xs font-normal': size === EAvatarSize.Xs,
              'text-sm font-medium': size === EAvatarSize.Sm,
              'text-md font-medium': size === EAvatarSize.Md,
              'text-lg font-medium': size === EAvatarSize.Lg,
              'text-xl font-medium': size === EAvatarSize.Xl,
            },
          )}>
          {label}
        </span>
      )}
    </span>
  );
};
