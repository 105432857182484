import React, { FC, Fragment } from 'react';
import { EPlatform, IRelease, EReleaseFileType } from '../../../electrolyse-shared/interfaces';
import { ReleaseFile } from './ReleaseFile';

interface IProps {
  release: IRelease;
  onUpdate: () => void;
}

export const ReleaseFiles: FC<IProps> = ({ release, onUpdate }) => {
  return (
    <Fragment>
      <div className='bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
        <dt className='text-sm leading-5 font-medium text-gray-500'>Update files</dt>
        <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
          <ul className='border border-gray-200 rounded-md'>
            <ReleaseFile
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.Windows}
              extension='exe'
              type={EReleaseFileType.Update}
            />
            <ReleaseFile
              border
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.Windows}
              extension='blockmap'
              type={EReleaseFileType.BlockMap}
            />
            <ReleaseFile
              border
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.MacOS}
              extension='zip'
              type={EReleaseFileType.Update}
            />
            <ReleaseFile
              border
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.MacOS}
              extension='blockmap'
              type={EReleaseFileType.BlockMap}
            />
            {/* <ReleaseFile
              border
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.Linux}
              extension='gz'
              type={EReleaseFileType.Update}
            /> */}
          </ul>
        </dd>
      </div>
      <div className='bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6'>
        <dt className='text-sm leading-5 font-medium text-gray-500'>Install files</dt>
        <dd className='mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2'>
          <ul className='border border-gray-200 rounded-md'>
            <ReleaseFile
              onUpdate={onUpdate}
              release={release}
              platform={EPlatform.Windows}
              extension='exe'
              type={EReleaseFileType.Installer}
            />
            <ReleaseFile
              onUpdate={onUpdate}
              border
              release={release}
              platform={EPlatform.MacOS}
              extension='dmg'
              type={EReleaseFileType.Installer}
            />
            {/* <ReleaseFile
              onUpdate={onUpdate}
              border
              release={release}
              platform={EPlatform.Linux}
              extension='gz'
              type={EReleaseFileType.Installer}
            /> */}
          </ul>
        </dd>
      </div>
    </Fragment>
  );
};
