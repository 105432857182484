import React, { useCallback, useRef, useState, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { Notification } from './Notification';
import { EPartialStyle } from '../../../enums/EPartialStyle';
import { usePortal } from '../../../hooks/usePortal';

const DEFAULT_LIFETIME = 5000;
const ANIMATION_TIME = 200;
const DEFAULT_WIDTH = 330;

interface IProps {
  rootContainerSelector: string;
  allowIdentical?: boolean;
  width?: number;
}

export interface INotification {
  id: number;
  type: EPartialStyle;
  title: string | ReactNode;
  body: string | ReactNode;
  show: boolean;
  timeout: number;
  closeCallback?: () => void;
  renderIcon?: (className: string) => ReactNode;
}

interface IAddNotificationOptions {
  type: EPartialStyle;
  body: string | ReactNode;
  title?: string | ReactNode;
  lifetime?: number;
  closeCallback?: () => void;
  renderIcon?: (className: string) => ReactNode;
}

export interface INotificationsContext {
  clearAll: () => void;
  addNotification: (options: IAddNotificationOptions) => number | null;
  removeNotification: (id: number) => void;
}

export const NotificationsContext = React.createContext<INotificationsContext>({
  clearAll: () => {},
  addNotification: () => null,
  removeNotification: () => {},
});

export const Notifications: React.FC<IProps> = props => {
  const { rootContainerSelector, allowIdentical, children, width = DEFAULT_WIDTH } = props;
  const root = usePortal(rootContainerSelector);
  const notificationsMapRef = useRef<Map<number, INotification>>(new Map());

  /**
   * Utility timestamp to force re-renders due to update the notifications Map ref.
   * Used to strict component updates controls rendering updates manually.
   */
  const update = useState(Date.now());

  useEffect(() => {
    return () => {
      clearAll();
    };
  }, []);

  const clearAll = () => {
    let index = 0;
    notificationsMapRef.current.forEach(notification => {
      setTimeout(() => {
        removeNotification(notification.id);
      }, index * 50);
      index++;
    });
  };

  const getId = useCallback(
    (value: string): number => {
      if (allowIdentical) {
        return Date.now();
      }

      let hash = 0;

      if (value.length === 0) {
        return hash;
      }

      for (let i = 0, l = value.length; i < l; i++) {
        let chr = value.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
      }

      if (hash < 0) {
        return -hash;
      } else {
        return hash;
      }
    },
    [allowIdentical],
  );

  const removeNotification = (id: number) => {
    const notification = notificationsMapRef.current.get(id);
    if (notification) {
      notification.show = false;
      update[1](Date.now());
      clearTimeout(notification.timeout);
    }
  };

  const addNotification = useCallback(
    (options: IAddNotificationOptions): number | null => {
      const { type, body, title, lifetime = DEFAULT_LIFETIME, closeCallback = () => {}, renderIcon } = options;
      const id = getId(`${type.toString()}${body}${title}`);

      if (!allowIdentical && notificationsMapRef.current.get(id)) {
        return null;
      }

      notificationsMapRef.current.set(id, {
        id,
        body,
        type,
        title,
        show: false,
        timeout: -1,
        closeCallback,
        renderIcon,
      });

      update[1](Date.now());

      requestAnimationFrame(() => {
        const notification = notificationsMapRef.current.get(id);

        if (notification) {
          notification.show = true;
          notification.timeout = window.setTimeout(() => {
            removeNotification(id);
          }, lifetime + ANIMATION_TIME);
          update[1](Date.now());
        }
      });

      return id;
    },
    [allowIdentical, getId, removeNotification],
  );

  return (
    <NotificationsContext.Provider
      value={{
        clearAll,
        addNotification,
        removeNotification,
      }}>
      {children}
      {root
        ? ReactDOM.createPortal(
            <React.Fragment>
              {notificationsMapRef.current.size > 0 && (
                <div className='fixed inset-0 flex flex-col justify-start items-center px-6 pt-2 pointer-events-none overflow-hidden h-screen'>
                  {Array.from(notificationsMapRef.current.values()).map(notification => {
                    return (
                      <Notification
                        width={width}
                        key={notification.id}
                        onDelete={() => {
                          notificationsMapRef.current.delete(notification.id);
                          update[1](Date.now());
                        }}
                        animationTime={ANIMATION_TIME}
                        notification={notification}
                      />
                    );
                  })}
                </div>
              )}
            </React.Fragment>,
            root,
          )
        : null}
    </NotificationsContext.Provider>
  );
};
