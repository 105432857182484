export const API_PATHS = {
  POST_LOGIN: '/auth/login',
  PUT_REGISTER: '/auth/register',
  GET_ME: '/auth/me',

  GET_PROJECTS: '/projects',
  GET_PROJECT: '/projects/:id',
  PUT_NEW_PROJECT: '/projects',
  GET_PROJECT_RELEASES: '/project/:id/releases',
  GET_PROJECT_INVITES: '/project/:id/invites',
  GET_PROJECT_STATS: '/project/:id/stats',

  GET_RELEASE: '/releases/:id',
  DELETE_RELEASE: '/releases/:id',
  PUT_NEW_RELEASE: '/releases',
  PATCH_RELEASE: '/releases/:id',
  POST_RELEASE_UPLOAD: '/releases/upload/:id',
  DELETE_RELEASE_DELETE_UPLOAD: '/releases/upload/:releaseId/:id',

  GET_INVITE_BY_CODE: '/invite/:code',
  PUT_NEW_INVITE: '/invite',
  DELETE_INVITE: '/invite/:id',
  POST_ACCEPT_INVITE: '/invite/:code',

  PUT_STATS_EVENTS: `/stats`,
  POST_SESSION: `/session`,
};
