import React, { FC, useContext } from 'react';
import { EPlatform, IRelease, EReleaseFileType } from '../../../electrolyse-shared/interfaces';
import classnames from 'classnames';
import { useModal } from '../../hooks/useModal';
import { UploadModal } from '../ui/modal/UploadModal';
import filesize from 'filesize';
import { FileLogo } from './FileLogo';
import { DangerModal } from '../ui/modal/DangerModal';
import axios from 'axios';
import { API_PATHS } from '../../../electrolyse-shared/api-paths';
import { NotificationsContext } from '../ui/notification/Notifications';
import { EPartialStyle } from '../../enums/EPartialStyle';
import { Badge } from '../ui/label/Badge';
import { EColor } from '../../common/colors';

interface IProps {
  release: IRelease;
  platform: EPlatform;
  type: EReleaseFileType;
  extension: string;
  onUpdate: () => void;
  border?: boolean;
}

export const ReleaseFile: FC<IProps> = ({ release, platform, type, border, extension, onUpdate }) => {
  const notificationsContext = useContext(NotificationsContext);
  const file = release.files.find(f => f.platform === platform && f.type === type);
  const uploadModal = useModal(
    ({ handleClose }) => (
      <UploadModal
        {...{
          onUpdate,
          extension,
          release,
          platform,
          type,
          handleClose,
        }}
      />
    ),
    { onEnter: () => {}, closeByEscapeKey: false, closeByOutsideClick: false },
  );
  const deleteFileModal = useModal(
    ({ handleClose }) => (
      <DangerModal
        handleClose={handleClose}
        handleAgree={handleDeleteFile}
        agreeText='Delete'
        cancelText='Cancel'
        title={`Delete ${platform} release file?`}>
        If so, then release status will be automatically changed to <strong className='text-gray-600'>Inactive</strong>{' '}
        until being reactivated again manually.
      </DangerModal>
    ),
    { onEnter: () => handleDeleteFile() },
  );
  const handleUpload = () => {
    if (!file) {
      uploadModal.open();
    }
  };
  const handleDeleteFileModal = (e: any) => {
    e.preventDefault();
    if (file) {
      deleteFileModal.open();
    }
  };
  const handleDeleteFile = async () => {
    try {
      await axios.delete(
        API_PATHS.DELETE_RELEASE_DELETE_UPLOAD.replace(':releaseId', release?._id ?? '').replace(
          ':id',
          file?._id ?? '',
        ),
      );
      notificationsContext.addNotification({
        type: EPartialStyle.Success,
        body: `${platform} update file deleted`,
        title: 'Success',
      });
    } catch (e) {
      notificationsContext.addNotification({
        type: EPartialStyle.Danger,
        body: e.response?.data?.error,
        title: 'Error',
      });
    }
    deleteFileModal.close();
    onUpdate();
  };

  return (
    <li
      onClick={handleUpload}
      className={classnames(
        'pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5 hover:bg-gray-100 transition-colors duration-200 cursor-pointer',
        {
          'border-t border-gray-200': border,
        },
      )}>
      <div className='w-0 flex-1 flex items-center'>
        <FileLogo platform={platform} />
        <span className='ml-2'>
          <Badge color={EColor.Gray}>{extension}</Badge>
        </span>
        <span className='text-gray-500 ml-2'>{file && filesize(file.size)}</span>
      </div>
      {Boolean(file) ? (
        <div className='ml-4 flex-shrink-0'>
          <button
            onClick={handleDeleteFileModal}
            className='text-gray-500 hover:text-red-500 transition duration-150 ease-in-out ml-4'>
            Delete
          </button>
          <a
            href={file?.url}
            className='font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out ml-4'>
            Download
          </a>
        </div>
      ) : (
        <div className='ml-4 flex-shrink-0'>
          <button className='font-medium text-blue-600 hover:text-blue-500 transition duration-150 ease-in-out ml-4'>
            Upload
          </button>
        </div>
      )}
    </li>
  );
};
